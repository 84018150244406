.slider-min-3 {
  display: flex;
  flex-wrap: wrap;
}

.slider-min-3-item {
  width: 25%;
}

@media screen and (max-width: 991px) {
  .slider-min-3-item {
    width: 33%;
  }
}

@media screen and (max-width: 767px) {
  .slider-min-3-item {
    width: 50%;
  }
}

@media screen and (max-width: 400px) {
  .slider-min-3-item {
    width: 95%;
  }
}
