@import "react-slideshow-image/dist/styles.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.base-text-web {
  font-size: 1.17rem !important;
}

.footer-text-size {
  font-size: 1rem !important;
}

.footer-icons {
  color: #f4f4f4 !important;
}

.dlab-post-media {
  height: 100%;
}

.about-industry .img-cover {
  height: 100%;
  object-fit: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.referenceList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.referenceItems {
  margin: 10px;
  width: 30%;
  cursor: pointer;
}

.referenceItems img {
  border-radius: 5px;
}

.site-button:hover {
  background-color: #222e5e !important;
}

.selected {
  color: #931914 !important;
}
.selected:after {
  color: #931914 !important;

  width: calc(100% + 2px) !important;
}

.project-carousel {
  max-width: 100%;
  padding: 0 !important;
  margin: 0 !important;
}

.itemSlider {
  padding: 0 !important;
}

.slider-each-type {
  cursor: pointer;
  height: 65vh;
}

.slider-each-type img {
  transition: all 0.4s ease-in-out;
  height: 100%;
  object-fit: cover;
}

.slider-home-container {
  position: relative;
  text-align: center;
  color: white;
  height: 100%;
}

.centered-div-slide-home-container {
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  border-radius: 3px;
  background-color: rgba(157, 34, 53, 0.8);
  height: 180px;
  width: 180px;
  transition: all 0.5s ease-in-out;
}

.centered-div-slide-home-container > h2 {
  color: #fff;
  font-size: 1.5rem;
  position: relative;
  top: 0.8rem;
  transition: all 0.4s ease-in-out;
}

.slider-each-type:hover .centered-div-slide-home-container {
  background-color: rgba(255, 255, 255, 0.8);
  width: 200px;
  height: 200px;
}

.slider-each-type:hover .centered-div-slide-home-container > h2 {
  color: #222e5e;
  font-size: 1.7rem;
}
.slider-each-type:hover img {
  filter: brightness(70%);
}

.imageReference {
  height: 350px !important;
  object-fit: cover !important;
}

@media screen and (max-width: 1480px) {
  .centered-div-slide-home-container {
    top: 45%;
    height: 150px;
    width: 150px;
  }

  .centered-div-slide-home-container > h2 {
    color: #fff;
    font-size: 1.3rem;
  }

  .slider-each-type:hover .centered-div-slide-home-container {
    width: 170px;
    height: 170px;
  }

  .slider-each-type:hover .centered-div-slide-home-container > h2 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1200px) {
  .imageReference {
    height: 270px !important;
  }
}

@media screen and (max-width: 992px) {
  .imageReference {
    height: 220px !important;
  }
}

@media screen and (max-width: 767px) {
  .centered-div-slide-home-container {
    top: 45%;
    height: 180px;
    width: 180px;
  }

  .imageReference {
    height: 220px !important;
  }

  .centered-div-slide-home-container > h2 {
    color: #fff;
    font-size: 1.5rem;
  }

  .slider-each-type:hover .centered-div-slide-home-container {
    width: 200px;
    height: 200px;
  }

  .slider-each-type:hover .centered-div-slide-home-container > h2 {
    font-size: 1.7rem;
  }

  .referenceItems {
    width: 44%;
  }
}

@media screen and (max-width: 450px) {
  .centered-div-slide-home-container {
    top: 45%;
    height: 150px;
    width: 150px;
  }

  .referenceItems {
    width: 80%;
  }

  .centered-div-slide-home-container > h2 {
    color: #fff;
    font-size: 1.3rem;
  }

  .slider-each-type:hover .centered-div-slide-home-container {
    width: 170px;
    height: 170px;
  }

  .slider-each-type:hover .centered-div-slide-home-container > h2 {
    font-size: 1.5rem;
  }
}
