.next,
.prev {
  position: absolute;
  top: 34.5%;
  font-size: 4rem;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  transition: all 0.6s ease-in-out;
}

.next {
  right: 20px;
}

.prev {
  left: 20px;
}

.next:hover,
.prev:hover {
  font-size: 4.3rem;
}

@media screen and (max-width: 1480px) {
  .next,
  .prev {
    top: 33.3%;
    font-size: 3.6rem;
  }

  .next:hover,
  .prev:hover {
    font-size: 3.9rem;
  }
}

@media screen and (max-width: 450px) {
  .next,
  .prev {
    top: 32.5%;
    font-size: 3rem;
  }

  .next:hover,
  .prev:hover {
    font-size: 3rem;
  }
}
