.reference-title {
  margin-bottom: 0.5rem !important;
  margin-top: 2rem !important;
}

.img-container {
  padding: 2rem;
}

.img-reference {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.dlab-media,
.dlab-media,
.dlab-post-media {
  height: 22rem;
}

@media screen and (max-width: 1199px) {
  .dlab-media,
  .dlab-media,
  .dlab-post-media {
    height: 17.2rem;
  }
}

@media screen and (max-width: 991px) {
  .dlab-media,
  .dlab-media,
  .dlab-post-media {
    height: 13rem;
  }
}

@media screen and (max-width: 767px) {
  .dlab-media,
  .dlab-media,
  .dlab-post-media {
    height: 14rem;
  }
}

@media screen and (max-width: 559px) {
  .dlab-media,
  .dlab-media,
  .dlab-post-media {
    height: 13.5rem;
  }
}

@media screen and (max-width: 500px) {
  .dlab-media,
  .dlab-media,
  .dlab-post-media {
    height: 12rem;
  }
}

@media screen and (max-width: 450px) {
  .dlab-media,
  .dlab-media,
  .dlab-post-media {
    height: 17.2rem;
  }
}
