/*===================== 
	Premery bg color 
=====================*/

:root {
  --color-primary: #931914;
  --color-secondry: #20507f;
  --color-hover: #222e5e;
}

/* Hex */
.bg-primary,
.meet-ask-row:after,
.dlab-separator.style-skew[class*="style-"]:after,
.dlab-separator.style-skew[class*="style-"]:before,
.dlab-tilte-inner.skew-title:after,
.date-style-2 .post-date,
.date-style-3.skew-date .post-date,
.date-style-3.skew-date .post-date:before,
.overlay-primary-light:after,
.overlay-primary-middle:after,
.overlay-primary-dark:after,
.widget-title:after,
.site-button,
.comments-area .comment-form p input[type="submit"],
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus,
.footer-line:after,
.testimonial-1 .quote-left:before,
.testimonial-2 .quote-left:before,
.site-filters .active > [class*="site-button"],
.list-num-count > li:before,
.date-style-4 .post-date strong,
.date-style-3 .post-date,
.date-style-2 .post-date,
#myNavbar li.active a,
.skew-angle:after,
.header-style-4.style-1 .header-nav .nav > li.active a:before,
.header-style-4.style-1 .header-nav .nav > li:hover > a:before,
.dlab-project-left:after,
.service-carousel .owl-prev,
.service-carousel .owl-next,
.about-us .dlab-tabs .nav-tabs li.active a,
.testimonial-text.bg-primary,
.sidenav ul li a:hover,
.owl-theme.dots-style-2 .owl-dots .owl-dot.active span,
.owl-btn-1.primary .owl-prev,
.owl-btn-1.primary .owl-next,
.owl-btn-2.primary .owl-prev,
.owl-btn-2.primary .owl-next,
.owl-slider-banner .owl-theme .owl-dots .owl-dot.active span,
.owl-slider-banner .owl-theme .owl-dots .owl-dot:hover span,
.product-item-size .btn.active,
.ui-widget-header,
.owl-theme.dots-style-3 .owl-dots .owl-dot.active span,
.owl-theme.dots-style-3 .owl-dots .owl-dot:hover span,
.owl-item.active.center .testimonial-text,
.icon-bx-wraper.hover:hover,
.portfolio-sep,
.portfolio-ic .dlab-img-overlay1:before,
.dlab-box-bg.active .icon-box-btn .site-button,
.dlab-accordion.primary .acod-head a,
.side-bar .widget_tag_cloud a:hover,
.ft-contact,
.site-footer.style1 .footer-bottom-in .footer-bottom-logo a,
.site-footer.style1 .footer-title:after,
button.scroltop,
.blog-post.post-style-1 .dlab-post-meta:after,
.owl-dots-primary-full.owl-theme .owl-dots .owl-dot span,
.owl-dots-primary-big.owl-theme .owl-dots .owl-dot span,
.service-media-bx:after,
.service-box.style3 .icon-bx-wraper:after,
.counter-style-5:after,
.counter-staus-box .play-btn span,
.dlab-team1:hover .dlab-info,
.dlab-team1:hover .dlab-info:after,
.ext-sidebar-menu.sidebar-widget ul.menu li:hover a,
.ext-sidebar-menu.sidebar-widget ul.menu li.current-menu-item a,
.ext-sidebar-menu ul.menu li:hover a,
.ext-sidebar-menu ul.menu li.active a,
.download-file,
.tabs-site-button .nav-tabs li a.active:focus,
.tabs-site-button .nav-tabs li a.active:hover,
.tabs-site-button .nav-tabs li a.active,
.header-curve.ext-header .logo-header:before,
.header-curve.ext-header .logo-header:after,
.header-curve.ext-header .extra-nav:before,
.header-curve.ext-header .extra-nav:after,
.social-curve:after,
.social-curve:before,
[class*="list-"].list-box > li:before,
.testimonial-12 .testimonial-detail,
.pagination-bx.primary .pagination > li > a,
.pagination-bx.primary .pagination > li > span,
.dlab-team5 .dlab-border-left:after,
.dlab-team5 .dlab-border-left:before,
.dlab-team5 .dlab-border-right:before,
.dlab-team5 .dlab-border-right:after,
.dlab-team4 .dlab-social-icon li a.site-button:hover,
.dlab-team5 .dlab-social-icon li a.site-button:hover,
.dlab-team6 .dlab-position,
.dlab-team6 .dlab-title:before,
.dlab-team6 .dlab-title:after,
.dlab-team6 .dlab-info:before,
.dlab-team6 .dlab-info:after,
.dlab-team9 .dlab-media.dlab-media-right .dlab-info-has,
.dlab-team9 .dlab-title,
.dlab-team11 .dlab-info,
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-sub-html,
.lg-toolbar,
.widget_getintuch-pro-details .pro-details i,
.exhibition-carousel:after,
.service-box.style5 .icon-content .link-btn,
.header-nav .nav > li.active > a:after,
.active.current .project-owbx,
.active.current .project-owbx:after,
.service-box.style1:after,
.portbox3 .portinner .port-down,
.service-box-3 .title,
.service-box.style4:hover .no,
.frame-box .header-nav .nav > li > a,
.frame-box .service-list li:after,
.frame-box .footer-title:after,
.request-form .request-form-header,
.request-form .input-group:after,
.icon-bx-ho [class*="icon-bx-"]:after,
.header-sidenav .is-fixed .extra-nav,
.header-sidenav .extra-nav,
.header-nav .nav > li > a:after,
.box-header .extra-nav .site-button:after,
.filters2 ul li a:after,
.navstyle3 .header-nav .nav > li > .mega-menu,
.navstyle3 .header-nav .nav > li .sub-menu,
.frame-box .site-header.center .is-fixed .container > .logo-header,
.header-nav .nav > li .mega-menu > li > ul li > a:after,
.header-nav .nav > li .sub-menu li > a:after,
.searchform input[type="submit"],
.widget_calendar table tbody td#today {
  background-color: #931914;
}

/*Hex color :hover */
.site-button:active,
.site-button:hover,
.site-button:focus,
.active > .site-button,
.bg-primary-dark,
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus,
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus,
.sidenav .closebtn:hover,
.sidenav .closebtn:focus,
.owl-btn-1.primary .owl-prev:hover,
.owl-btn-1.primary .owl-next:hover,
.owl-btn-2.primary .owl-prev:hover,
.owl-btn-2.primary .owl-next:hover,
.comments-area .comment-form p input[type="submit"]:hover,
.comments-area .comment-form p input[type="submit"]:focus,
.comments-area .comment-form p input[type="submit"]:active,
.pagination-bx.primary .pagination > li > a:hover,
.pagination-bx.primary .pagination > li > span:hover,
.pagination-bx.primary .pagination > li.active > span,
.pagination-bx.primary .pagination > li.active > a,
.box-header .extra-nav .site-button:hover:after,
.comment-form .form-submit input:active,
.comment-form .form-submit input:focus,
.comment-form .form-submit input:hover,
.searchform input[type="submit"]:hover,
.searchform input[type="submit"]:focus,
.searchform input[type="submit"]:active,
.btnhover14::after,
.btnhover13::after,
.btnhover15::after {
  background-color: #ff8022;
}
.about-us .dlab-tabs .nav-tabs li.active {
  border-right: 4px solid #ff8022;
}
/* Rgba color */
.dlab-info-has.bg-primary,
.dlab-info-has.bg-secondry:hover,
.tp-bannertimer.tp-bottom {
  background-color: rgba(26, 188, 156, 0.9);
}
/*===================== 
	Premery text color 
=====================*/
a,
.text-primary,
.primary li:before,
.breadcrumb-row ul li a,
.header-nav .nav > li.active > a,
.header-nav
  .nav
  > li.current-menu-item
  > a
  .header-nav
  .nav
  > li
  .sub-menu
  li
  a:hover,
.header-nav .nav > li:hover > a,
.header-nav .nav > li .mega-menu > li ul a:hover,
.header-nav .nav > li .sub-menu li:hover > a,
.nav-dark.header-nav .nav > li .sub-menu li:hover > a,
.nav-dark.header-nav .nav > li .mega-menu > li ul a:hover,
blockquote:before,
ol.comment-list li.comment .reply a,
footer a:active,
footer a:focus,
footer a:hover,
footer h1 a,
footer h2 a,
footer h3 a,
footer h4 a,
footer h5 a,
footer h6 a,
footer p a,
button.scroltop,
.testimonial-1 .testimonial-position,
.testimonial-4 .testimonial-name:after,
.testimonial-2 .testimonial-position,
.testimonial-3 .testimonial-position,
.testimonial-3 .quote-left:before,
.acod-head a:after,
.acod-head a,
.acod-head a:hover,
.acod-head a.collapsed:hover,
.dlab-tabs .nav-tabs > li > a i,
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
.site-button.outline,
.site-button-link:hover,
.item-info-in ul li a:hover,
.dlab-post-meta.text-primary i,
.dlab-post-meta.text-primary ul li,
.dlab-post-meta.text-primary ul li a,
.dlab-post-meta i,
.comments-area p:before,
.about-8-service .icon-bx-wraper:hover a,
.header-nav .nav > li > a.nav-link.active,
.site-footer.style2 .widget.widget_getintuch li i,
.call-action.style2 i.call-icon:after,
.testimonial-15 .testimonial-position,
.section-full.bg-black .testimonial-15 .quote-left:before,
.section-full.bg-black .testimonial-15 .quote-right:after,
.section-full.bg-secondry .testimonial-15 .quote-left:before,
.section-full.bg-secondry .testimonial-15 .quote-right:after,
.testimonial-14 .testimonial-detail:after,
.testimonial-14.quote-left:before,
.dlab-post-meta ul li,
.contact-no .no,
[class*="list-"].style2.list-box > li:after,
[class*="list-"].list-box.reverse > li:before,
.testimonial-13 .quote-left:before,
.testimonial-15 .quote-left:before,
.testimonial-15 .quote-right:after,
.testimonial-9 .testimonial-position,
.testimonial-10 .testimonial-position,
.site-filters.style1 li.active a,
.site-filters.style1.white li.active a,
.overlay-icon .check-km i.icon-bx-xs,
.service-list ul li i,
.project-owbx i,
.filters2 ul li a i,
.navstyle1 .main-bar.bg-primary .header-nav .nav > li.active > a,
.navstyle1 .main-bar.bg-primary .header-nav .nav > li:hover > a,
.frame-box .project-bx .info .title a,
.dlab-box-bg .icon-box-btn .site-button,
.service-box-4 .service-images i,
.list-2 li:hover:before,
.widget_rss ul .rss-date {
  color: #931914;
}

@media only screen and (max-width: 991px) {
  .nav-dark.header-nav .nav > li > a:hover,
  .nav-dark.header-nav .nav > li > a:active,
  .nav-dark.header-nav .nav > li > a:focus {
    color: #931914;
  }
}
/*===================== 
	Premery border color 
=====================*/
.navbar-toggle,
.testimonial-4 .testimonial-pic,
.testimonial-4:after,
.testimonial-4 [class*="quote-"],
button.scroltop,
blockquote,
.widget_gallery li:hover,
.owl-slider-banner .owl-theme .owl-dots .owl-dot.active,
.owl-slider-banner .owl-theme .owl-dots .owl-dot:hover,
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
input[type="checkbox"]:checked + label:before,
input[type="checkbox"] + label:hover:before,
input[type="radio"] + label:hover:before,
.owl-theme.dots-style-3 .owl-dots .owl-dot.active,
.owl-theme.dots-style-3 .owl-dots .owl-dot:hover,
.side-bar .widget_tag_cloud a:hover,
.counter-style-5:hover,
.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover,
.site-filters .site-button.outline,
button.scroltop.style3:after,
.navstyle3 .header-nav .nav > li > .mega-menu,
.navstyle3 .header-nav .nav > li .sub-menu,
.site-button.outline {
  border-color: #931914;
}
.owl-item.active.center .testimonial-8 .testimonial-text:after {
  border-top: 25px solid #931914;
}
.dlab-tabs.border-top .nav-tabs > li.active > a {
  border-top-color: #931914;
}
.comments-area .comment-form p input[type="submit"]:hover,
.comments-area .comment-form p input[type="submit"]:focus,
.comments-area .comment-form p input[type="submit"]:active {
  border-color: #ff8022;
}
.dlab-team4 .dlab-media:after {
  border-color: #931914 #e6e5e5 #e6e5e5 #931914 !important;
}

/*===================== 
	Secondery bg color
 =====================*/
.bg-secondry,
.testimonial-14 .testimonial-detail,
.lg-outer .lg-toogle-thumb,
.lg-outer .lg-thumb-outer,
.lg-outer .lg-img-wrap,
.lg-outer .lg-item,
.frame-box .header-nav .nav > li.active > a,
.frame-box .header-nav .nav > li > a:hover {
  background-color: #e44800;
}

/*===================== 
	Secondery text color
 =====================*/
.text-secondry {
  color: #2d3239;
}

/*===================== 
	New Important
 =====================*/
a.text-primary:hover,
button.scroltop.white {
  color: #ff8022 !important;
}
.text-primary,
.site-footer .footer-bottom a:hover,
footer .widget_services ul li a:hover,
.overlay-icon .check-km i.icon-bx-xs {
  color: #931914 !important;
}
.bg-primary,
.pagination-bx.gray .pagination > li.active > a,
.pagination-bx.gray .pagination > li > a:hover {
  background-color: #931914 !important;
}
a.bg-primary:focus,
a.bg-primary:hover {
  background-color: #ff8022 !important;
}
.testimonial-14,
.dlab-team11-area.dots-theme.owl-theme .owl-dots .owl-dot.active span,
.dlab-team11 .dlab-media,
.about-year span,
.img-ho1:after,
.frame-box .about-box,
.service-box-4:hover .service-images img {
  border-color: #931914 !important;
}
.testimonial-14 .testimonial-detail:after,
.testimonial-14 .testimonial-detail:before {
  border-top-color: #931914 !important;
}
.contact-no .contact-left {
  border-right-color: #931914 !important;
}
button.scroltop {
  box-shadow: -4px 4px 24px -10px #931914 !important;
}
.navstyle2 .header-nav .nav > li.active > a:after,
.navstyle2 .header-nav .nav > li:hover > a:after {
  box-shadow: inset 0 0 0 2px #931914;
}
