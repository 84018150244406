.sub-category-list p {
  font-size: 1rem;
  margin: 0;
  margin-bottom: 0.2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 10px;
  cursor: pointer;
  border-radius: 2px;
  transition: all 0.4s ease-in-out;
  background: #931914;
  font-weight: 500;
  color: #fff;
  border-radius: 3px;
}
.sub-category-list .active {
  color: #931914;
  background: #f2f2f2;
}

.sub-category-list p:hover {
  color: #931914;
  background: #f2f2f2;
}

.dropdown-category-list {
  background-color: #931914;
  color: #fff;
  font-weight: 500;
  display: none;
}

.Dropdown-option {
  color: #fff;
}

.product-itemmm {
  background-color: #f1f1f1;
  border-radius: 3px;
  transition: all 0.4s ease-in-out;
}

.product-itemmm img {
  height: 180px;
  object-fit: cover;
}

.product-itemmm:hover {
  background-color: #dddddd;
}

.autres {
  background-color: #931914;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.autres p {
  margin: 0;
  padding-left: 5px;
  padding-right: 5px;
}

.description-list-buttons {
  list-style: none !important;
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  flex-direction: row;
  justify-content: center;
}

.description-list-buttons button,
.site-button-custom {
  background-color: #931914 !important;
  transition: all 0.3s ease-in;
}

.description-list-buttons button {
  margin: 0 1rem;
  width: 300px;
}

@media screen and (max-width: 1201px) {
  .product-itemmm img {
    height: 140px;
  }

  .description-list-buttons button {
    width: 250px;
    margin: 0rem 1rem;
  }
}

.item-info-in p {
  color: #fff;
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
}

@media screen and (max-width: 991px) {
  .description-list-buttons button {
    width: auto;
    margin: 1rem 1rem;
  }
}

@media screen and (max-width: 767px) {
  .description-list-buttons button {
    width: 300px;
    margin: 1rem 1rem;
  }

  .dropdown-category-list {
    display: block;
  }
  /* .sub-category-list {
    display: none;
  } */
}

@media screen and (max-width: 575px) {
  .product-itemmm img {
    height: auto;
  }
}
