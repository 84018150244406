.dis-block {
  display: block;
}

.bg-img-fix {
  background-size: cover;
}

.bg-secondry {
  background-color: #152332;
}

.text-gray-dark {
  color: #9a9a9a;
}

.main-slider .button-lg {
  padding: 15px 30px;
}

.owl-none .owl-nav {
  display: none;
}

.client-logo ul {
  margin: 0 -10px;
}

.client-logo ul li {
  display: inline-block;
  /* float        : left; */
  padding: 0 10px;
  width: 33.33%;
  margin-bottom: 20px;
}

.client-logo img {
  width: 100%;
}

.dlab-bnr-inr .dz_error-404-area {
  height: 100vh;
}

.table-responsive {
  overflow-x: unset;
}

@media only screen and (max-width: 1280px) {
  .blog-info li {
    font-size: 13px;
  }
  .table-responsive {
    overflow-x: auto;
  }
}

@media only screen and (max-width: 767px) {
  .dlab-tabs.vertical .tab-pane {
    padding: 0;
  }
  .footer-bottom .text-left,
  .footer-bottom .text-right {
    text-align: center;
  }
}

.skew-content-box ul li a {
  color: #fff;
  font-size: 14px;
  width: auto;
  padding: 0 10px;
  line-height: 24px;
}

.skew-content-box {
  display: inline;
  left: -1px;
  padding: 5px 20px;
  position: absolute;
  top: -15px;
  /* width   : auto */
}

.skew-content-box:after {
  background-color: inherit;
  content: "";
  height: 100%;
  position: absolute;
  right: -10px;
  top: 0;
  transform: skewX(20deg);
  -moz-transform: skewX(20deg);
  -webkit-transform: skewX(20deg);
  -o-transform: skewX(20deg);
  width: 20px;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .main-slider .button-lg {
    font-size: 14px;
    padding: 8px 10px;
  }
}

footer .footer-bottom .fa-heart {
  animation: 2.5s ease 0s normal none infinite running animateHeart;
  font-size: 16px;
}

@keyframes animateHeart {
  0% {
    transform: scale(1);
  }
  5% {
    transform: scale(1.2);
  }
  10% {
    transform: scale(1.1);
  }
  15% {
    transform: scale(1.25);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.overlay-primary-dark:after,
.overlay-primary-light:after,
.overlay-primary-middle:after {
  background: var(--color-primary);
  background: -moz-linear-gradient(
    45deg,
    var(--color-primary) 8%,
    var(--color-hover) 100%
  );
  background: -Winkit-linear-gradient(
    45deg,
    var(--color-primary) 8%,
    var(--color-hover) 100%
  );
  background: linear-gradient(
    45deg,
    var(--color-primary) 8%,
    var(--color-hover) 100%
  );
}

.h4,
.h5,
.h6,
h4,
h5,
h6 {
  font-weight: 600;
}

.box-sort {
  background-color: #fff;
  border: 1px solid #ebedf2;
  border-radius: 4px;
}

.box-sort .box-sort-in {
  border-top: 1px solid #ebedf2;
}

.dis-tbl {
  display: table;
}

.dis-tbl-cell {
  display: table-cell;
  vertical-align: middle;
}

.widget.bg-white {
  border: 1px solid #ebedf2;
}

.dlab-social-icon.dlab-social-icon-lg li a {
  color: #fff;
  font-size: 14px;
  height: 35px;
  line-height: 34px;
  padding: 0;
  width: 35px;
}

.dlab-social-icon-lg.dlab-social-icon li {
  display: inline-block;
  font-size: 12px;
  padding: 0;
}

.dlab-social-icon-lg.dlab-social-icon.border li a {
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.contact-style-1 input {
  height: 50px;
}

.contact-style-1 .site-button {
  height: 50px;
  width: 100%;
}

.contact-style-1 .form-control {
  padding: 10px 20px;
  border-radius: 4px;
}

.contact-style-1 .form-control:focus,
.contact-style-1 .form-control:hover {
  border-color: silver;
}

.contact-style-1 h2 {
  margin-top: 0;
}

.contact-style-1 .border-1 {
  border: 1px solid #eee;
}

.contact-style-1 .dlab-social-icon-lg {
  border-top: 1px solid #eee;
  padding-top: 20px;
  text-align: left;
  width: 100%;
  margin: 0;
}

.contact-style-1 .dlab-social-icon-lg a {
  text-align: center;
}

.contact-style-1 .dlab-social-icon-lg li a:hover {
  background: #fff;
  color: #000;
  border: 1px solid #eee;
}

.contact-style-1 .form-group {
  margin-bottom: 25px;
}

.counter {
  position: relative;
}

.about-progress-box .about-progress-bar {
  height: 5px;
  border-radius: 5px;
  width: 100%;
  overflow: hidden;
}

.about-progress-box .about-progress {
  height: 5px;
  border-radius: 5px;
}

.about-progress-box {
  margin-bottom: 30px;
}

.faq-1 .panel {
  margin-bottom: 20px;
  transition: all 0.5s;
}

.faq-1 .acod-title a {
  padding: 20px 40px 20px 20px;
  color: #000;
}

.faq-1 .panel:hover {
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.1);
  transition: all 0.5s;
}

.faq-1 .acod-head a::after {
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  right: 20px;
}

.faq-video {
  position: relative;
}

.faq-video .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  font-size: 65px;
}

.title-box {
  font-weight: 500;
}

.faq-2 .panel .acod-body,
.faq-2 .panel .acod-head a {
  border-width: 1px 0;
}

.faq-2 .panel .acod-head a {
  padding: 20px 40px 20px 0;
  color: #000;
}

.faq-2 .panel .acod-content {
  margin: 20px 0;
}

.about-8-service .icon-bx-wraper {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}

.choses-info {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 1;
  text-align: center;
}

.choses-info-content [class*="col-"] {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.choses-info-content [class*="col-"]:last-child {
  border-right: 0;
}

.inquiry-form .form-control {
  border-width: 0 0 1px;
  font-size: 16px;
}

.inquiry-form .input-group-addon {
  border-width: 0 0 1px;
  font-size: 18px;
  padding: 9px 0 3px;
  vertical-align: top;
  border-bottom: 1px solid #e1e6eb;
}

.inquiry-form {
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.05);
  border-radius: 0;
  margin-top: 0;
  height: 100%;
  background: #fff;
  padding: 30px;
  border-radius: 4px;
}

.icon-bx-wraper.expertise {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.01);
}

.inquiry-form.box-shadow {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
}

.contact-page-9 .icon-bx-wraper {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid #505050;
}

.inquiry-form.contact-project {
  margin-top: -50px;
}

.chosesus-content .list-checked li {
  margin-bottom: 10px;
}

.contact-full .dlab-bnr-inr {
  padding-bottom: 0;
}

.inquiry-form.inner {
  padding: 20px;
}

.dz_error-404 .dz_error {
  border-radius: 50%;
  font-size: 70px;
  font-weight: 700;
  height: 200px;
  line-height: 200px;
  margin: 20px 0;
  text-align: center;
  width: 200px;
}

.dz_error-405 .dz_error {
  color: #dfdfdf;
  font-family: poppins;
  font-size: 200px;
  font-weight: 700;
  line-height: 200px;
}

.dz_error-404 .error-head {
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.dz_error-405 .error-head {
  font-size: 50px;
  font-weight: 700;
}

.dz_error-405 .error-content {
  font-size: 21px;
  font-weight: 500;
}

@media only screen and (max-width: 1024px) {
  .inquiry-form {
    padding: 30px;
  }
  .dz_error-405 .dz_error {
    font-size: 150px;
    line-height: 150px;
  }
  .title-box span {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .testimonials .testimonial-content {
    padding-left: 0;
  }
  .choses-info-content h4 {
    font-size: 14px;
    margin-bottom: 0;
  }
  .choses-info-content h2 {
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 0;
  }
  .inquiry-form.contact-project {
    margin-top: 0;
  }
  .inquiry-form {
    padding: 20px;
  }
  .choses-info-content > div {
    padding: 20px 15px;
  }
  .portfolio .filters .btn a {
    padding: 5px 10px;
    text-align: center;
  }
  .dz_error-404 .dz_error {
    font-size: 35px;
    height: 100px;
    line-height: 100px;
    margin: auto;
    width: 100px;
  }
  .dz_error-404 .error-head {
    font-size: 24px;
    margin-bottom: 5px;
  }
  .dz_error-405 .dz_error {
    font-size: 120px;
    line-height: 120px;
  }
  .dz_error-405 .error-head {
    font-size: 35px;
    margin: 10px 0;
  }
  .dz_error-405 .dlab-divider {
    margin: 15px 0;
  }
  .dz_error-405 .col-lg-5.m-tb30 {
    margin-bottom: 0;
  }
  .dz_error-404 [class*="col-"].m-tb30 {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 360px) {
  .dz_error-404 p {
    font-size: 14px;
    margin-bottom: 15px;
  }
  .dz_error-404 .error-head {
    font-size: 22px;
    margin-bottom: 10px;
    line-height: 32px;
  }
  .dz_error-405 .col-lg-5.m-tb30 {
    margin-top: 0;
  }
}

.portfolio .site-filters {
  margin-bottom: 30px;
}

.portfolio .dlab-gallery-box {
  margin-bottom: 0;
  padding: 7px;
}

.portfolio .btn {
  font-size: 12px;
}

.portfolio .dlab-box:hover .dlab-img-overlay1::before,
.portfolio .dlab-img-overlay1:hover::before {
  opacity: 0.8;
}

.portfolio p {
  margin: 0;
}

.portfolio p small {
  opacity: 0.7;
}

.portfolio .dlab-box:hover .overlay-icon,
.portfolio .ow-portfolio:hover .overlay-icon {
  margin-top: 0;
  transition: all 0.2s;
  opacity: 1;
}

.portfolio .dlab-box .overlay-icon,
.portfolio .ow-portfolio .overlay-icon {
  margin-top: 15px;
  transition: all 0.2s;
  opacity: 0;
}

.portfolio .icon-bx-xs {
  border-radius: 50%;
}

.portfolio .icon-bx-xs {
  width: 46px;
  height: 46px;
  line-height: 46px;
}

.portfolio-ic a {
  color: #000;
}

.portfolio-ic .dez-info a:hover {
  color: #24c4dd;
}

.portfolio-ic a:hover {
  color: #fff;
}

.portfolio-ic a:hover i {
  background: 0 0;
  border: 2px solid #fff;
}

.portfolio-ic .overlay-icon a {
  margin: 0 7px;
}

.portfolio .owl-next,
.portfolio .owl-next:hover,
.portfolio .owl-prev,
.portfolio .owl-prev:hover {
  background-color: #fff;
  color: #000;
  padding: 17px 17px;
}

.img-content-style-2 .link-list,
.img-hvr-content-style-1 .link-list,
.img-hvr-content-style-3 .link-list,
.img-hvr-content-style-4 .link-list {
  position: absolute;
  bottom: 20px;
  right: 20px;
  text-align: right;
  margin: 0;
}

.img-hvr-content-style-2 .link-list {
  right: 20px;
  top: 20px;
  position: absolute;
}

.img-hvr-content-style-2 .dlab-info-has-text,
.img-hvr-content-style-4 .dlab-info-has-text {
  position: absolute;
  bottom: 20px;
  left: 20px;
  text-align: left;
}

.dlab-divider.margin-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.img-hvr-content-style-3 .dlab-info-has-text {
  position: absolute;
  top: 20px;
  left: 20px;
  text-align: left;
}

.no-hover .overlay-bx {
  opacity: 1;
  visibility: visible;
}

.no-hover[class*="dlab-img-overlay"]:after,
.no-hover[class*="dlab-img-overlay"]:before {
  opacity: 0.5;
}

.img-content-style-1 .overlay-icon .link-list a i {
  background-color: rgba(0, 0, 0, 0);
}

.img-content-style-3 .link-list {
  list-style: none;
  position: absolute;
  top: 20px;
  right: 20px;
}

.content-box-style .dlab-img-overlay12:before {
  opacity: 0.2;
}

.content-box-style.style1 .box-visible {
  transform: scale(0);
  -o-transform: scale(0);
  -moz-transform: scale(0);
  -webkit-transform: scale(0);
  visibility: hidden;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.content-box-style.style1:hover .box-visible {
  transform: scale(1);
  -o-transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  visibility: visible;
}

#masonry {
  width: 100%;
}

.subscribe-form .form-control:focus {
  border-color: rgba(255, 255, 255, 0.8);
  background-color: rgba(0, 0, 0, 0);
}

.footer-info-bar .icon-bx-wraper.bx-style-1 {
  background: #fff;
}

.footer-info-bar .icon-bx-wraper.bx-style-1 .dlab-tilte,
.footer-info-bar .icon-bx-wraper.bx-style-1 .icon-sm,
.footer-info-bar .icon-bx-wraper.bx-style-1 p {
  color: #000;
}

@media only screen and (max-width: 1024px) {
  .footer-info-bar .icon-content p {
    font-size: 12px;
  }
  .footer-info-bar [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
  }
  .footer-info-bar .icon-bx-wraper {
    padding: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .footer-info-bar [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.mfp-bg {
  z-index: 9999999;
}

.mfp-wrap {
  z-index: 9999999;
}

.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-sub-html,
.lg-toolbar {
  background-color: var(--color-primary);
}

.lg-outer .lg-img-wrap,
.lg-outer .lg-item,
.lg-outer .lg-thumb-outer,
.lg-outer .lg-toogle-thumb {
  background-color: var(--color-secondry);
}

#lg-counter,
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-outer .lg-toogle-thumb,
.lg-toolbar .lg-icon {
  color: #fff;
}

.check-km {
  cursor: pointer;
}

.features-box .dlab-info .dlab-title {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;
}

.features-box .dlab-info .dlab-title a {
  color: var(--color-primary);
}

.features-box .dlab-info p {
  margin-bottom: 15px;
}

.features-info .title {
  font-size: 30px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 40px;
  padding-right: 30px;
  margin-bottom: 30px;
}

.service-info {
  max-width: 780px;
  margin: auto;
}

.service-info .title {
  font-size: 36px;
  font-weight: 600;
  line-height: 50px;
}

.service-info p {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 0;
}

.call-action {
  padding: 50px 0;
}

.call-action .title {
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 5px;
}

.call-action.style1 {
  padding: 30px 0;
}

.call-action.style1 .title {
  font-size: 24px;
}

.about-service2 {
  padding-top: 150px;
  padding-bottom: 150px;
}

.home-slider.style1 .title {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 50px;
  max-width: 600px;
  margin: 0 auto 15px;
}

.home-slider.style1 p {
  font-size: 16px;
  max-width: 700px;
  margin: 0 auto 20px;
}

.home-slider.style2 .title {
  font-size: 45px;
  letter-spacing: 1px;
  line-height: 56px;
  max-width: 700px;
  margin: 0 auto 20px;
}

.service-box.style1 {
  background-color: #fff;
  padding: 40px 35px;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.05);
  text-align: center;
  position: relative;
  margin: 10px 0 40px;
}

.service-box.style1 .title {
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 0;
  font-weight: 600;
}

.service-box.style1:hover:after {
  width: 100%;
  transform-origin: right;
  -moz-transform-origin: right;
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  -o-transform-origin: right;
  right: auto;
  left: 0;
}

.service-box.style1:after {
  content: "";
  height: 5px;
  background: var(--color-primary);
  width: 0;
  position: absolute;
  bottom: 0;
  right: -1px;
  transition: all 0.8s;
  -moz-transition: all 0.8s;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
}

.service-box.style2 {
  padding: 60px;
  align-self: center;
  align-items: center;
  flex-wrap: wrap;
  display: flex;
  height: 100%;
}

.service-box.style2 .title span {
  font-weight: 400;
}

.service-box.style2 .title {
  font-size: 45px;
  line-height: 55px;
  font-weight: 700;
}

.service-box.style2 p {
  font-size: 16px;
}

.about-industry .img-cover {
  min-height: 500px;
}

.service-box.style3 .icon-bx-wraper {
  padding: 50px 30px;
  background: #fff;
  margin-bottom: 30px;
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.05);
  z-index: 1;
  width: 370px;
  height: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-box.style3 .icon-bx-wraper:before {
  content: attr(data-name);
  position: absolute;
  font-size: 100px;
  right: 30px;
  top: 30px;
  line-height: 80px;
  font-weight: 700;
  color: #000;
  opacity: 0.05;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.service-box.style3 .icon-lg i {
  font-size: 70px;
  line-height: 70px;
  color: var(--color-primary);
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.service-box.style3 .dlab-tilte {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  margin-bottom: 15px;
  line-height: 30px;
}

.service-box.style3 .icon-lg {
  margin-bottom: 25px;
}

.service-box.style3 .icon-bx-wraper:after {
  content: "";
  height: 100%;
  width: 0;
  background-color: var(--color-primary);
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  transform-origin: left;
  -moz-transform-origin: left;
  -ms-transform-origin: left;
  -webkit-transform-origin: left;
  -o-transform-origin: left;
  transition: all 0.8s;
  -moz-transition: all 0.8s;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
}

.service-box.style3 .icon-bx-wraper:hover,
.service-box.style3 .icon-bx-wraper:hover .dlab-tilte,
.service-box.style3 .icon-bx-wraper:hover .icon-lg i,
.service-box.style3 .icon-bx-wraper:hover:before {
  color: #fff;
}

.service-box.style3 .icon-bx-wraper:hover:before {
  opacity: 0.2;
}

.service-box.style3 .icon-bx-wraper:hover:after {
  width: 100%;
  left: 0;
  right: auto;
  transform-origin: right;
  -moz-transform-origin: right;
  -ms-transform-origin: right;
  -webkit-transform-origin: right;
  -o-transform-origin: right;
}

.widget-blog .dlab-post-header .post-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
}

.widget-blog .widget-post {
  border-bottom: 1px solid #e9e9e9;
  padding: 0 0 20px;
  margin-bottom: 20px;
}

.widget-post .dlab-post-media {
  width: 125px;
}

.date-style-2 .dlab-info {
  position: unset;
}

.date-style-2 .post-date {
  top: 20px;
  border-radius: 5px;
  width: 65px;
  font-family: Poppins, sans-serif;
}

.date-style-2 .post-date > strong:after {
  content: unset;
}

.date-style-2 .post-date strong {
  font-size: 16px;
  padding: 5px 5px 0;
}

.date-style-2 .post-date span {
  padding: 0 5px 8px;
  text-transform: capitalize;
}

.video-bx.style1 {
  position: relative;
}

.video-bx.style1 .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-bx .play-btn {
  font-size: 70px;
}

.project-box.style1 {
  position: relative;
}

.project-box.style1 .project-info {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 25px;
}

.project-box.style1 .project-info .dlab-tilte {
  margin-bottom: 0;
}

.project-box.style1 .project-info .dlab-tilte a,
.project-box.style1 .project-info-hover .dlab-tilte a {
  color: #fff;
}

.project-box.style1 .project-info-hover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--color-primary);
  padding: 30px;
  color: #fff;
  opacity: 0;
  transition: all 0.5s;
}

.project-box.style1:hover .project-info-hover {
  opacity: 1;
  transition: all 0.5s;
}

.project-box.style1 .project-info-hover .dlab-tilte {
  font-size: 22px;
  line-height: 30px;
  margin: 15px 0;
}

.blog-post-content {
  margin: 0;
}

.blog-post-content [class*="col-"] {
  padding: 0;
}

.blog-post-content .blog-post .dlab-info {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-width: 1px 0 1px 1px;
  padding: 35px;
  transition: all 0.5s;
}

.blog-post-content [class*="col-"]:last-child .blog-post .dlab-info {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.blog-post-content .blog-post:hover .dlab-info {
  background-color: #edf5f8;
  transition: all 0.5s;
}

.blog-post-content .blog-post .dlab-post-text p {
  font-size: 15px;
  line-height: 26px;
}

.blog-post-content .blog-post .dlab-post-text {
  margin-bottom: 20px;
  margin-top: 15px;
}

.blog-post-content .blog-post .dlab-post-meta {
  margin-bottom: 15px;
}

.about-history .title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 15px;
}

.about-history {
  padding-right: 50px;
}

.about-history p {
  font-size: 15px;
  line-height: 26px;
}

.about-history-carousel,
.history-box {
  position: relative;
}

.history-box .dlab-media {
  width: 65%;
}

.history-box .history-info .title {
  font-size: 24px;
  color: #fff;
  margin-bottom: 20px;
}

.history-box .history-info {
  background-color: var(--color-primary);
  padding: 40px;
  color: #fff;
  width: 55%;
  position: absolute;
  right: 0;
  bottom: 0;
}

.history-box .history-info p {
  margin-bottom: 0;
  font-size: 15px;
  line-height: 26px;
}

.about-history-carousel .owl-nav {
  position: absolute;
  right: 0;
  top: 0;
}

.counter-fly-box {
  margin: -100px 0 10px;
  z-index: 99;
  position: relative;
  background-color: #fff;
  padding: 30px 30px 10px;
}

.about-bx-2 .ext {
  text-decoration: underline;
  font-weight: 500;
  font-size: 24px;
  color: var(--color-primary);
}

.about-bx-2 .title {
  font-size: 38px;
  line-height: 50px;
}

.counter-style-5 {
  padding: 50px 20px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  overflow: hidden;
  transition: all 0.8s;
  -moz-transition: all 0.8s;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
  z-index: 1;
}

.counter-style-5 .counter {
  font-size: 60px;
  line-height: 60px;
  font-weight: 600;
  letter-spacing: 3px;
  margin-bottom: 10px;
}

.counter-style-5 p {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
}

.counter-style-5:hover {
  transform: translateY(-15px);
  -moz-transform: translateY(-15px);
  -webkit-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  -o-transform: translateY(-15px);
  border-color: var(--color-primary);
}

.counter-style-5:after {
  content: "";
  height: 0;
  width: 100%;
  background-color: var(--color-primary);
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  -webkit-transform-origin: top;
  -o-transform-origin: top;
  transition: all 0.8s;
  -moz-transition: all 0.8s;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
}

.counter-style-5:hover .dlab-separator {
  background: #fff !important;
}

.counter-style-5:hover:after {
  height: 100%;
  top: auto;
  bottom: 0;
  transform-origin: bottom;
  -moz-transform-origin: bottom;
  -ms-transform-origin: bottom;
  -webkit-transform-origin: bottom;
  -o-transform-origin: bottom;
}

.counter-staus-box .section-head .title {
  font-size: 40px;
  line-height: 55px;
  font-weight: 700;
  color: #fff;
}

.counter-staus-box .play-btn {
  font-size: 18px;
  line-height: 70px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 40px;
  color: #fff;
  display: inline-block;
  margin-top: 30px;
}

.counter-staus-box .play-btn span {
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  background-color: var(--color-primary);
  display: inline-block;
  border-radius: 70px;
  color: #fff;
  font-size: 22px;
  margin-right: 15px;
  position: relative;
}

.dlab-team1 {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 5px 5px 30px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.5s;
}

.dlab-team1 .dlab-info {
  position: absolute;
  bottom: -55px;
  background: #fff;
  width: 100%;
  padding: 20px 30px;
  text-align: center;
  transition: all 0.8s;
  -moz-transition: all 0.8s;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
}

.dlab-team1 .dlab-position {
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  color: var(--color-primary);
}

.dlab-team1 .dlab-social-icon {
  display: block;
  margin-top: 20px;
}

.dlab-team1 .dlab-title {
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 600;
}

.dlab-team1 .dlab-info:after {
  content: "";
  width: 15px;
  height: 15px;
  background: #fff;
  position: absolute;
  top: -7px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  -moz-transform: translateX(-50%) rotate(45deg);
  -webkit-transform: translateX(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) rotate(45deg);
  -o-transform: translateX(-50%) rotate(45deg);
}

.dlab-team1:hover .dlab-info {
  bottom: 0;
  background: var(--color-primary);
  transition: all 0.8s;
  -moz-transition: all 0.8s;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
}

.dlab-team1:hover .dlab-position {
  color: #fff;
  opacity: 0.6;
}

.dlab-team1:hover .dlab-title a {
  color: #fff;
}

.dlab-team1:hover .dlab-social-icon li a {
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.dlab-team4 .dlab-social-icon li {
  margin: 0 2px;
}
.dlab-team1 .dlab-social-icon li a {
  width: 32px;
  height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 16px;
  line-height: 30px;
  padding: 0;
}

.dlab-team1:hover .dlab-info:after {
  background: var(--color-primary);
}

.testimonial-1 .testimonial-text p {
  font-size: 20px;
  line-height: 40px;
  color: #737984;
}

.testimonial-1 .testimonial-name {
  font-size: 20px;
  color: #fff;
  line-height: 35px;
  margin-bottom: 5px;
}

.testimonial-1 .testimonial-text {
  max-width: 980px;
  margin: auto;
}

.quote-icon {
  max-width: 100px;
  margin-left: auto;
  margin-right: auto;
}

.blog-post.post-style-1 {
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

.blog-post.post-style-1 .dlab-post-meta li:after {
  content: none;
}

.blog-post.post-style-1 .dlab-post-meta {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 12px;
  position: relative;
}

.blog-post.post-style-1 .dlab-post-meta:after {
  content: "";
  width: 50px;
  height: 2px;
  background: var(--color-primary);
  position: absolute;
  bottom: -1px;
  left: 0;
}

.blog-post.post-style-1 .dlab-post-info {
  padding: 30px;
}

.blog-post.post-style-1 .dlab-post-text {
  margin-bottom: 0;
}

.blog-post.post-style-1 .post-title {
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 600;
  line-height: 34px;
  font-size: 20px;
}

.blog-post.post-style-1 .dlab-post-readmore {
  margin-top: 15px;
  margin-bottom: 0;
}

.blog-post.post-style-1 .dlab-post-meta li:last-child:after {
  content: "";
}

.blog-post.post-style-1 .dlab-post-meta li:after {
  content: "|";
  display: inline-block;
  font-weight: 400;
  margin-left: 5px;
  opacity: 0.5;
}

.post-style-2 .dlab-post-info {
  margin-top: 20px;
}

.post-style-2 .dlab-post-title .post-title {
  margin-top: 0;
  margin-bottom: 15px;
  font-weight: 500;
}

.post-style-2 .dlab-post-meta {
  margin-bottom: 10px;
}

.contact-no-area .title {
  margin-top: 0;
  font-size: 60px;
  color: #fff;
  font-weight: 500;
  line-height: 80px;
  margin-bottom: 40px;
}

.contact-no p {
  margin-bottom: 0;
  font-size: 14px;
  opacity: 0.8;
}

.contact-no .no {
  font-size: 35px;
  line-height: 45px;
  margin-bottom: 0;
  color: var(--color-primary);
  font-weight: 500;
  margin-top: 0;
}

.contact-no {
  color: #fff;
  display: flex;
  align-items: center;
}

.contact-no .contact-right .site-button {
  padding: 15px 30px;
}

.contact-no .contact-right {
  padding-left: 40px;
}

.contact-no .contact-left {
  padding-right: 40px;
  border-right: 3px solid;
}

.counter-box h2 {
  font-size: 45px;
  padding: 30px 15px;
}

.contact-img-bx img {
  width: 100%;
  position: absolute;
  right: 0;
  bottom: -80px;
}

.service-media-bx {
  padding: 25px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 6px 6px 35px 0 rgba(0, 0, 0, 0.05);
  transition: all 0.8s;
  -moz-transition: all 0.8s;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
}

.service-media-bx:hover {
  transform: translateY(-15px);
  -moz-transform: translateY(-15px);
  -webkit-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  -o-transform: translateY(-15px);
}

.service-media-bx:after {
  content: "";
  height: 4px;
  width: 0;
  background-color: var(--color-primary);
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  transform-origin: left;
  -moz-transform-origin: left;
  -ms-transform-origin: left;
  -webkit-transform-origin: left;
  -o-transform-origin: left;
  transition: all 0.8s;
  -moz-transition: all 0.8s;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
}

.service-media-bx:hover:after {
  width: 100%;
  left: 0;
  right: auto;
  transform-origin: right;
  -moz-transform-origin: right;
  -ms-transform-origin: right;
  -webkit-transform-origin: right;
  -o-transform-origin: right;
}

.service-media-bx .dlab-title a {
  color: #000;
}

.service-media-bx p {
  color: #8e8e99;
  line-height: 30px;
  margin-bottom: 15px;
}

.service-media-bx .dlab-title {
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 34px;
}

.service-media-bx .dlab-info {
  padding: 35px 0 20px 0;
}

.service-media-bx .site-button {
  text-transform: uppercase;
  font-weight: 600;
  padding: 12px 30px;
}

.about-ser-carousel.owl-carousel .owl-stage-outer {
  overflow: inherit;
}

.about-ser-carousel.owl-carousel .owl-stage-outer .owl-item {
  opacity: 0.2;
}

.about-ser-carousel.owl-carousel .owl-stage-outer .owl-item.active {
  opacity: 1;
}

.ft-contact {
  background: var(--color-primary);
  border-radius: 10px;
  display: flex;
}

.ft-contact .ft-contact-bx {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  position: relative;
  padding: 25px 40px 25px 120px;
  z-index: 1;
}

.ft-contact .ft-contact-bx .title {
  margin: 0 0 10px 0;
  font-size: 22px;
  font-weight: 500;
  color: #fff;
}

.ft-contact .ft-contact-bx:last-child:after {
  content: none;
}

.ft-contact .ft-contact-bx:after {
  content: "";
  height: 100%;
  width: 50px;
  position: absolute;
  right: -15px;
  top: 0;
  z-index: -1;
  background-image: url(../images/icon/right.png);
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  opacity: 0.5;
}

.ft-contact .ft-contact-bx p {
  color: #fff;
  line-height: 28px;
  margin-bottom: 0;
  font-size: 16px;
}

.ft-contact .ft-contact-bx img {
  width: 55px;
  height: 55px;
  position: absolute;
  left: 45px;
  top: 36px;
  transition: all 0.8s;
  -moz-transition: all 0.8s;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
}

.ft-contact .ft-contact-bx:hover img {
  transform: scale(1.2);
}

.site-footer.style1 .dlab-newsletter:after {
  content: "";
  background: #191f23;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 70px;
  left: 0;
  bottom: 0;
}

.site-footer.style1 .dlab-newsletter {
  overflow-x: hidden;
  background-color: #fff;
  position: relative;
  z-index: 1;
}

.site-footer.style1 .footer-top {
  background: #191f23;
  background-size: cover;
  background-position: center;
  padding: 70px 0 0 0;
}

.site-footer.style1 .footer-line {
  position: relative;
}

.site-footer.style1 .footer-bottom {
  background-color: #191f23;
  font-size: 14px;
  border-top: 1px solid #303639;
  padding: 0;
}

.site-footer.style1 .footer-bottom ul {
  margin: 0;
}

.site-footer.style1 .footer-bottom a {
  padding: 0 4px;
  color: #999;
}

.site-footer.style1 .footer-title {
  margin: 0;
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 20px;
  margin-bottom: 30px;
  position: relative;
  color: #fff;
}

.site-footer.style1 .footer-title:after {
  content: "";
  background: var(--color-primary);
  width: 40px;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.site-footer.style1 .widget_about p {
  margin-bottom: 20px;
}

.site-footer.style1 .widget_about a {
  color: var(--color-primary);
  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
}

.site-footer.style1 .list-2 li {
  width: 80%;
  float: left;
  padding: 0 0 12px 0;
  font-size: 16px;
}

.site-footer.style1 .list-2 li:before {
  content: none;
}

.site-footer.style1 .list-2 li a {
  text-decoration: underline;
}

.site-footer.style1 .widget_subscribe p {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 28px;
}

.site-footer.style1 .widget.widget_about p {
  font-size: 16px;
  line-height: 28px;
}

.site-footer.style1 .widget_subscribe .input-group .form-control {
  height: 60px;
  border-radius: 4px 0 0 4px;
  border: 0;
  padding: 10px 30px;
}

.site-footer.style1
  .widget_subscribe
  .input-group
  .input-group-addon
  .site-button {
  height: 60px;
  border-radius: 0 4px 4px 0;
  font-size: 18px;
  padding: 15px 25px;
}

.site-footer.style1 .widget_subscribe .input-group .input-group-addon {
  padding: 0;
  border: 0;
  background: 0 0;
}

.site-footer.style1 .footer-bottom-in {
  display: flex;
  justify-content: space-between;
}

.site-footer.style1 .footer-bottom-in .footer-bottom-logo a img {
  height: 50px;
}

.site-footer.style1 .footer-bottom-in .footer-bottom-logo a {
  display: inline-block;
  background: var(--color-primary);
  padding: 10px 20px;
}

.site-footer.style1 .footer-bottom-in .dlab-social-icon li:first-child a {
  border-left: 1px solid #303639;
}

.site-footer.style1 .footer-bottom-in .dlab-social-icon li a {
  padding: 0;
  width: 60px;
  height: 70px;
  font-size: 20px;
  color: #3b4044;
  border-right: 1px solid #303639;
  line-height: 70px;
}

.portfolio .check-km i {
  background-color: #fff;
  color: #000;
  cursor: pointer;
}

.portfolio .check-km:hover i {
  background: 0 0;
  border: 2px solid #fff;
  color: #fff;
}

.video-play-icon a {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  text-align: center;
  line-height: 80px;
  color: #fff;
  box-shadow: 0 0 0 10px rgba(238, 49, 49, 0.2);
  display: block;
  font-size: 28px;
  margin: auto;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.video-bx {
  position: relative;
  z-index: 1;
  border-radius: 6px;
  overflow: hidden;
}

.video-bx .video-play-icon {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.video-section {
  background-repeat: no-repeat;
  background-position: right bottom;
}

.blog-post.blog-single .dlab-post-meta {
  margin-bottom: 0;
}

.blog-post.blog-single .dlab-post-title .post-title {
  margin-top: 10px;
  margin-bottom: 10px;
}

ol.comment-list li.comment p {
  line-height: 24px;
  font-size: 14px;
}

ol.comment-list li.comment .comment-meta {
  text-transform: capitalize;
  font-size: 14px;
  line-height: 20px;
}

ol.comment-list li.comment .comment-meta a {
  margin-left: 5px;
}

ol.comment-list li.comment .comment-author .fn {
  color: #000;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
}

ol.comment-list li.comment .comment-author {
  margin-bottom: 5px;
}

ol.comment-list li.comment .reply a {
  font-size: 14px;
  font-weight: 600;
}

.comments-area .comment-form p input[type="submit"] {
  cursor: pointer;
}

ol.commentlist li .meta strong {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin-right: 5px;
}

ol.commentlist li .description p {
  font-size: 14px;
  line-height: 24px;
}

ol.commentlist li .meta span {
  color: #000;
  font-size: 14px;
}

.shop-service-info p {
  font-size: 14px;
  line-height: 24px;
}

.dlab-info-has.no-hover .dlab-post-meta {
  margin-bottom: 0;
}

.dlab-info-has.no-hover .dlab-post-title .post-title {
  margin-top: 0;
}

.ow-entry-title {
  font-size: 18px;
  text-transform: capitalize;
}

.ow-carousel-entry .ow-entry-text p {
  font-size: 14px;
  line-height: 24px;
}

.box-about-list .icon-bx-wraper {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 30px 30px;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.box-about-list .img-cover {
  height: 100%;
}

.box-about-list .icon-bx-wraper p {
  opacity: 0.8;
  font-size: 15px;
  line-height: 26px;
}

.box-about-list .icon-bx-wraper .dlab-tilte {
  color: #fff;
  font-weight: 500;
  text-decoration: underline;
}

.box-about-list .icon-bx-wraper:hover {
  background-color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.box-about-list .icon-bx-wraper:hover .dlab-tilte,
.box-about-list .icon-bx-wraper:hover .text-white,
.box-about-list .icon-bx-wraper:hover p {
  color: #000 !important;
}

.ext-sidebar-menu ul.menu li a {
  background: #fff;
  display: block;
  border-radius: 4px;
  padding: 15px 25px;
  margin-bottom: 10px;
  color: #000;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 5px 12px 20px 0 rgba(0, 0, 0, 0.05);
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.ext-sidebar-menu ul.menu li {
  display: block;
  padding: 0 !important;
  border: 0;
}

.ext-sidebar-menu ul.menu li:before {
  content: none;
}

.ext-sidebar-menu ul.menu li.active a,
.ext-sidebar-menu ul.menu li:hover a,
.ext-sidebar-menu.sidebar-widget ul.menu li.current-menu-item a,
.ext-sidebar-menu.sidebar-widget ul.menu li:hover a {
  background: var(--color-primary);
  color: #fff;
}

.download-file {
  background: var(--color-primary);
  color: #fff;
  padding: 25px 25px 20px 20px;
  border-radius: 4px;
}

.download-file ul li a {
  background: #000;
  color: #fff;
  display: block;
  margin-bottom: 10px;
  border-radius: 4px;
  width: 100%;
  padding: 15px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.download-file ul li a .text {
  display: inline;
  position: relative;
  margin-right: 3px;
}

.download-file .title {
  color: #fff;
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.widget_getintuch-pro-details .pro-details strong {
  font-weight: 600;
  color: #000;
  font-size: 15px;
}

.widget_getintuch-pro-details {
  background-color: #f5f5f5;
  padding: 30px 30px 0;
}

.widget_getintuch-pro-details .pro-details {
  padding-left: 55px;
  margin-bottom: 30px;
  font-weight: 600;
  position: relative;
  color: #000;
  font-size: 15px;
}

.widget_getintuch-pro-details .pro-details i {
  background-color: var(--color-primary);
  height: 40px;
  width: 40px;
  color: #fff;
  border-radius: 50px;
  line-height: 40px;
  font-size: 16px;
  top: 0;
}

.gallery-filters .check-km i {
  font-size: 20px;
  color: #fff;
}

.overlay-icon .check-km i.icon-bx-xs {
  background-color: #fff;
  color: var(--color-hover);
}

.testimonial-1 .testimonial-name {
  color: #737984 !important;
}

.testimonial-1.text-white .testimonial-name,
.testimonial-1.text-white .testimonial-text p {
  color: rgba(255, 255, 255, 0.8) !important;
}

.dlab-box ul li .check-km i {
  color: var(--color-primary);
}

.code-box {
  box-shadow: none;
  margin: 0 0 25px;
  padding: 20px;
  color: #fff;
  background-color: #191f23;
  white-space: pre;
  text-shadow: 0 1px 0 #000;
  border-radius: 5px;
  border-bottom: 1px solid #555;
  max-height: 700px;
}

.code-box.active {
  user-select: all;
}

.footer-box,
.header-box {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 20px 40px 0 40px;
  margin-bottom: 50px;
  background: rgba(0, 0, 0, 0.01);
}

.footer-box .title,
.header-box .title {
  font-size: 50px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: var(--color-primary);
  margin-bottom: 20px;
}

.header-box p {
  font-size: 16px;
  line-height: 28px;
  color: #000;
  opacity: 0.9;
}

#copyButton.btn {
  position: absolute;
  right: 30px;
  top: 15px;
  background: #eee;
  color: #000;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
  padding: 4px 10px;
}

#copyButton.btn.active {
  background: #449cff;
  color: #fff;
}

.slick-slider.project-list,
.project-list {
  margin-bottom: 50px;
}

.project-owbx {
  padding: 20px 20px 15px 20px;
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  position: relative;
  margin: 20px 0;
  transition: all 0.8s;
  -moz-transition: all 0.8s;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
}

.project-owbx .title {
  margin: 0;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  line-height: 28px;
}

.project-owbx i {
  font-size: 50px;
  color: var(--color-primary);
  margin-bottom: 10px;
  line-height: 50px;
  display: inline-block;
}

.slick-current .project-owbx,
.active.current .project-owbx {
  background: var(--color-primary);
  box-shadow: 0 0 40px -20px var(--color-primary);
}
.slick-current .project-owbx .title,
.slick-current .project-owbx i,
.active.current .project-owbx .title,
.active.current .project-owbx i {
  color: #fff;
}

.slick-current .project-owbx:after,
.active.current .project-owbx:after {
  content: "";
  background: var(--color-primary);
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: -18px;
  transform: rotate(45deg) translate(-50%);
  left: 50%;
  transition: all 0.8s;
  -moz-transition: all 0.8s;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
}

.roboto-condensed h1,
.roboto-condensed h2,
.roboto-condensed h3,
.roboto-condensed h4,
.roboto-condensed h5,
.roboto-condensed h6 {
  font-family: "Roboto Condensed", sans-serif;
}

.box-header.header-transparent .main-bar {
  border: 0;
}

.box-header.site-header .main-bar .container {
  background: #fff;
}

.box-header.header-transparent .extra-nav .site-button-link,
.box-header.header-transparent .header-nav .nav > li > a,
.box-header.header-transparent .navbar-toggler {
  color: #000;
}

.box-header.header-transparent .top-bar {
  padding: 12px 0;
}

.box-header .dlab-topbar-right .site-button-link {
  color: #fff;
  font-size: 14px;
}

.box-header.header-transparent .top-bar {
  border-bottom: 0;
}

.box-header.header-transparent .top-bar.text-black .dlab-topbar-left li a,
.box-header.header-transparent
  .top-bar.text-black
  .topbar-social
  li
  a.site-button-link {
  color: #000;
}

.box-header.header-transparent .top-bar .dlab-topbar-left li a:hover,
.box-header.header-transparent
  .top-bar
  .topbar-social
  li
  a.site-button-link:hover {
  color: var(--color-primary);
}

.box-header .extra-nav {
  padding: 0;
  border: 0;
}

.box-header .dlab-topbar-left ul li a {
  font-size: 14px;
}

.box-header .dlab-topbar-right .site-button-link i {
  margin: 0;
}

@media only screen and (min-width: 991px) {
  .box-header .header-nav .nav > li > a {
    padding: 22px 10px 22px 10px;
  }
}

.box-header .header-nav .nav > li > a {
  border: 0;
  font-family: "Roboto Condensed", sans-serif;
}

.box-header .extra-nav .site-button {
  height: 68px;
  display: flex;
  align-items: center;
  margin-right: -16px;
  padding: 0 30px;
  margin-left: 30px;
  position: relative;
  font-family: "Roboto Condensed", sans-serif;
}

.box-header .extra-nav .site-button:after {
  content: "";
  width: 40px;
  height: 100%;
  position: absolute;
  left: -12px;
  background: var(--color-primary);
  transform: skew(-20deg);
  -moz-transform: skew(-20deg);
  -webkit-transform: skew(-20deg);
  -ms-transform: skew(-20deg);
  -o-transform: skew(-20deg);
  top: 0;
  transition: all 0.8s linear;
  -moz-transition: all 0.8s linear;
  -webkit-transition: all 0.8s linear;
  -ms-transition: all 0.8s linear;
  -o-transition: all 0.8s linear;
}

.box-header.header-transparent .is-fixed .main-bar {
  background-color: transparent;
  box-shadow: none;
}

.box-header .extra-nav .site-button:hover:after {
  background: var(--color-hover);
  transition: all 0.8s linear;
}

.box-header.site-header .is-fixed .main-bar .container {
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
}

.about-year {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.const-about .section-head.style2,
.exhibition-bx .section-head {
  margin-bottom: 30px;
}

.about-year span {
  font-size: 85px;
  color: #000;
  font-weight: 600;
  position: relative;
  z-index: 1;
  font-family: "Roboto Condensed", sans-serif;
  border: 5px solid;
  width: 120px;
  height: 150px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-year p {
  font-family: "Roboto Condensed", sans-serif;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 28px;
  margin-left: 20px;
  color: #000;
  text-transform: uppercase;
}

.list-details li {
  color: #000;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.list-details li:first-child {
  border-top: 0;
}

.list-details {
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: 430px;
}

.project-slider:after {
  content: "";
  background: #ebebeb;
  height: 100%;
  width: calc(50% - 80px);
  position: absolute;
  top: 0;
  left: 0;
}

.project-slider .about-content {
  max-width: 600px;
  padding-left: 45px;
}

.portbox3 {
  border-radius: 6px;
  margin-bottom: 10px;
}

.portbox1 .portinner,
.portbox3 .overlay-bx {
  transform: scale(0.2);
  -webkit-transform: scale(0.2);
  -moz-transform: scale(0.2);
  -ms-transform: scale(0.2);
  -o-transform: scale(0.2);
  transition: all 0.8s;
  -moz-transition: all 0.8s;
  -webkit-transition: all 0.8s;
  -o-transition: all 0.8s;
  -ms-transition: all 0.8s;
}

.portbox1 .overlay-bx,
.portbox3 .overlay-bx {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.portbox1:hover .portinner,
.portbox3:hover .overlay-bx {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}

.portbox3 .portinner {
  max-width: 250px;
  width: 250px;
  min-width: 250px;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.portbox3 .portinner .port-up {
  background: #fff;
  border-radius: 6px;
  padding: 20px;
}

.portbox3 .portinner .port-down {
  /* background    : var(--color-primary); */
  position: absolute;
  top: 50px;
  height: 100%;
  width: calc(100% + 20px);
  vertical-align: bottom;
  display: flex;
  /* align-items   : end; */
  text-align: center;
  flex-direction: row;
  border-radius: 6px;
  z-index: -1;
  left: -10px;
}

.portbox3 .portinner .port-up span {
  text-transform: uppercase;
  font-weight: 600;
  color: #5e5e5e;
  font-family: Montserrat;
  font-size: 14px;
}

.portbox3 .portinner .port-up .port-title {
  margin-bottom: 0;
}

.portbox3 .portinner .port-down a {
  width: 100%;
  color: #000;
  font-weight: 400;
  font-family: Montserrat;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  padding: 15px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.portbox3 .portinner .port-down a.btn-link:hover i {
  text-decoration: none;
}

.filters2 ul {
  display: flex;
  margin: 0 -10px;
}

.filters2 ul li {
  flex: 0 0 25%;
  max-width: 25%;
  min-width: 25%;
  width: 25%;
  margin: 0;
  border: 0;
  padding: 0 10px;
}

.filters2 ul li a i {
  margin-right: 10px;
  color: var(--color-primary);
  font-size: 40px;
  line-height: 40px;
}

.filters2 ul li a {
  width: 100%;
  margin: 0;
  display: flex;
  padding: 12px 10px;
  font-size: 22px;
  color: #000;
  border-bottom: 3px solid #eee;
  align-items: center;
  justify-content: center;
  font-family: "Roboto Condensed", sans-serif;
  position: relative;
}

.filters2 ul li a:after {
  content: "";
  height: 3px;
  background: var(--color-primary);
  width: 0;
  position: absolute;
  bottom: -3px;
  right: -1px;
  transition: all 0.8s;
  -moz-transition: all 0.8s;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
}

.filters2 ul li.active a:after,
.filters2 ul li.active a:hover:after {
  width: 100%;
  transform-origin: right;
  -moz-transform-origin: right;
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  -o-transform-origin: right;
  right: auto;
  left: 0;
}

.project-info-box {
  position: relative;
}

.project-info-box .project-content {
  background: #fff;
  position: absolute;
  left: 0;
  top: 50%;
  padding: 40px 50px;
  width: 500px;
  transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
  opacity: 0;
  transition: all 0.8s;
  -moz-transition: all 0.8s;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
}

.slick-current .project-info-box .project-content,
.active .project-info-box .project-content {
  opacity: 1;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
}

.project-carousel-1 .owl-nav {
  display: flex;
  align-items: center;
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.ov-hidden,
.project-carousel {
  overflow: hidden;
}
.project-carousel-1 .owl-next,
.project-carousel-1 .owl-prev {
  padding: 0;
  background: 0 0;
  font-family: Oswald, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  display: flex;
  align-items: center;
  letter-spacing: 5px;
  margin: 0 15px;
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
}

.project-carousel-1 .owl-next i,
.project-carousel-1 .owl-prev i {
  font-size: 22px;
}

.section-head.style2 .title {
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 10px;
}

.section-head.style2 p {
  padding-top: 0;
  font-size: 15px;
  line-height: 26px;
}

.sr-iconbox .dlab-tilte {
  font-weight: 600;
  margin-bottom: 10px;
}

.sr-iconbox.style2 {
  padding: 20px;
  transition: all 0.8s;
  -o-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
}

.sr-iconbox.style2:hover {
  background: #fff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
}

.img-ho1 {
  position: relative;
  z-index: 1;
}

.img-ho1:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border: 20px solid;
  z-index: -1;
  transition: all 0.8s;
  -moz-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
  -webkit-transition: all 0.8s;
}

.img-ho1:hover:after {
  right: -15px;
  top: -15px;
}

.img-ho1 img {
  transition: all 0.8s;
  -moz-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
  -webkit-transition: all 0.8s;
}

.img-ho1:hover img {
  transform: translate(-15px, 15px);
  -moz-transform: translate(-15px, 15px);
  -ms-transform: translate(-15px, 15px);
  -webkit-transform: translate(-15px, 15px);
  -o-transform: translate(-15px, 15px);
}

.service-box-3 .dlab-info {
  position: absolute;
  bottom: -25px;
  width: calc(100% - 40px);
  left: 20px;
  z-index: 1;
}

.service-box-3 .title {
  background-color: var(--color-primary);
  margin-top: 0;
  border-radius: 4px;
  font-size: 18px;
  color: #fff;
  padding: 12px 50px 12px 30px;
  position: relative;
  font-weight: 500;
  margin-bottom: 0;
}

.service-box-3 .title a {
  color: #fff;
}

.service-box-3 .title:after {
  content: "\f105";
  width: 40px;
  height: 40px;
  background: #fff;
  position: absolute;
  right: 6px;
  color: #000;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  line-height: 40px;
  font-family: FontAwesome;
}

.service-box.style4 .title {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  line-height: 32px;
}

.service-box.style4 {
  background: #fff;
  padding: 30px 50px 40px 50px;
  border-radius: 4px;
  text-align: center;
}

.service-box.style4 .no {
  background-color: #eee;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 22px;
  color: #000;
  border-radius: 50px;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.service-box.style4:hover .no {
  background-color: var(--color-primary);
  color: #fff;
}

.our-team .social-list li {
  display: inline-block;
  font-size: 16px;
  margin: 0 2px;
}

.our-team .social-list {
  padding: 0;
  margin: 10px 0 0;
}

.our-team .team-title-bx {
  margin-top: 20px;
}

.our-team .title {
  font-size: 24px;
  margin-bottom: 5px;
  line-height: 35px;
}

.exhibition-carousel:after {
  content: "";
  position: absolute;
  width: calc(100% - 70px);
  height: 100%;
  right: 0;
  top: 0;
  background-color: #f8991d;
  z-index: -1;
}

.exhibition-carousel {
  padding: 20px 50px 20px 0;
  position: relative;
  z-index: 1;
  margin: 0 !important;
}
.exhibition-carousel .item {
  padding: 0;
}
.exhibition-carousel .owl-dots {
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.exhibition-carousel .owl-dot {
  width: 12px;
  height: 12px;
  background: #fff;
  border-radius: 20px;
  margin: 10px 0;
}

.exhibition-carousel .owl-dot.active {
  background: var(--color-primary);
}

.exhibition-bx .title {
  font-size: 50px;
  line-height: 58px;
}

.portbox1 {
  transition: all 0.5s;
  transform: scale(1);
}

.portbox1 > img {
  transform: scale(1.05);
  transition: all 0.5s;
}

.portbox1 .overlay-bx {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.portbox1 .portinner {
  transform: scale(0.2);
  -webkit-transform: scale(0.2);
  -moz-transform: scale(0.2);
  -ms-transform: scale(0.2);
  -o-transform: scale(0.2);
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
}

.portbox1.style1 .overlay-bx .port-title {
  font-size: 30px;
  line-height: 32px;
  margin-bottom: 15px;
}

.portbox1.style1 .portinner span {
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 5px;
  font-weight: 500;
}

.portbox1 .overlay-bx span {
  display: block;
  margin-bottom: 10px;
}

.portbox1 .overlay-bx .port-title a {
  color: #fff;
}

.portbox1:hover {
  transform: scale(1.05);
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.2);
}

.portbox1:hover > img {
  transform: scale(1);
}

.portbox1:hover .portinner {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}

.latest-project-info {
  padding: 100px 130px;
}

.latest-project-info h2 {
  font-size: 50px;
  line-height: 60px;
}

.latest-project-info p {
  color: rgba(255, 255, 255, 0.75);
  font-weight: 300;
}

.sr-iconbox1 {
  padding: 50px 30px;
  background: #fff;
  text-align: center;
  border-radius: 4px;
}

.sr-iconbox1 .dlab-tilte {
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 10px;
  line-height: 32px;
}

.sr-iconbox1 p {
  color: #000;
  font-size: 15px;
  opacity: 0.7;
}

.sr-iconbox1 .icon-lg {
  position: relative;
  z-index: 1;
}

.sr-iconbox1 .icon-lg:after {
  content: "";
  width: 100%;
  height: 100%;
  background: #6a1a1f;
  position: absolute;
  left: 0;
  z-index: -1;
  border-radius: 10px 10px 10px 10px;
  transform: scale(1.3) rotate(15deg);
  opacity: 0.05;
}

@media only screen and (max-width: 1024px) {
  .service-box.style2 {
    padding: 30px;
  }
  .service-box.style2 .title {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 15px;
  }
  .team-box-type .team-p-info li {
    font-size: 14px;
  }
  .team-box-type .team-mamber-img {
    width: 40%;
  }
  .team-box-type p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 5px;
  }
  .team-box-type .team-info-box ul {
    margin: 10px 0;
  }
  .team-box-type .team-info-box h4.dlab-title {
    font-size: 18px;
    margin-bottom: 5px;
  }
  .team-box-type .team-info-box {
    padding: 10px 15px;
  }
  .about-progress .max-w700,
  .box-about-list .max-w700 {
    padding: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .our-story h2.title {
    font-size: 36px;
    line-height: 46px;
  }
  .our-story h4.title {
    font-size: 20px;
    line-height: 30px;
  }
  .call-action .title,
  .call-action p,
  .call-action.style1 .title {
    text-align: center;
  }
  .call-action .site-button,
  .call-action.style1 .site-button {
    margin: 10px auto 0;
  }
  .team-box-type .team-info-box {
    padding: 10px 30px;
  }
  .team-box-type .team-info-box h4.dlab-title {
    font-size: 22px;
    margin-bottom: 12px;
  }
  .about-progress .max-w700,
  .box-about-list .max-w700 {
    padding: 40px 0;
  }
  .our-story-thum {
    display: none;
  }
  .inquiry-form.contact-project {
    height: auto;
    margin-bottom: 20px;
  }
  .chosesus-content {
    padding-top: 50px;
  }
  .choses-info-content [class*="col-"] {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .inquiry-form.inner {
    padding: 30px;
  }
  .dz_error-405 .text-center {
    text-align: left !important;
  }
  .dz_error-404-area {
    display: table-cell !important;
  }
  .dlab-bnr-inr h1 {
    margin-bottom: 10px;
  }
  .dlab-bnr-inr.dlab-bnr-inr-sm .dlab-bnr-inr-entry {
    padding-top: 160px;
  }
}

@media only screen and (max-width: 767px) {
  .service-info .title {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 15px;
  }
  .team-info-box,
  .team-mamber-img {
    display: table-cell;
  }
  .team-box-type .team-info-box h4.dlab-title {
    font-size: 18px;
    margin-bottom: 5px;
  }
  .team-box-type .team-info-box {
    padding: 15px 20px;
  }
  .dlab-bnr-inr.dlab-bnr-inr-sm .dlab-bnr-inr-entry {
    height: 250px;
    padding-top: 65px;
  }
  .pricingtable-wrapper.style2 .pricingtable-bx {
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .about-progress .max-w700,
  .box-about-list .max-w700 {
    padding: 30px;
  }
  .dz_error-404 .dz_error {
    margin: 0;
  }
  .dlab-tabs.vertical .tab-pane {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 360px) {
  .service-box.style2 .title {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 10px;
  }
  .site-footer.style1 .footer-bottom-in .footer-bottom-logo a img {
    height: 30px;
  }
  .site-footer.style1 .footer-bottom-in .dlab-social-icon li a {
    width: 40px;
    height: 50px;
    font-size: 15px;
    line-height: 50px;
  }
  .site-footer.style1 .footer-top {
    padding: 50px 0 25px 0;
  }
  .site-footer.style1 .footer-title {
    padding-bottom: 15px;
    margin-bottom: 25px;
  }
  .site-footer.style1 .widget_subscribe .input-group .form-control {
    height: 50px;
    padding: 10px 20px;
  }
  .site-footer.style1
    .widget_subscribe
    .input-group
    .input-group-addon
    .site-button {
    height: 50px;
    font-size: 14px;
    padding: 12px 20px;
  }
  .our-story h2.title {
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 10px;
  }
  .our-story > span {
    font-size: 18px;
    margin-bottom: 5px;
  }
  .call-action .title,
  .call-action.style1 .title {
    font-size: 20px;
    line-height: 30px;
  }
  .call-action p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .section-head .title {
    font-size: 28px;
    line-height: 38px;
  }
  .section-head p {
    line-height: 26px;
  }
  .testimonial-8 .testimonial-text {
    padding: 20px;
  }
  .dlab-tabs .tab-content p,
  .testimonial-8 .testimonial-text p {
    font-size: 14px;
    line-height: 26px;
  }
  .testimonial-8 .testimonial-pic {
    margin-right: 10px;
    width: 70px;
    height: 70px;
  }
  .testimonial-8 .testimonial-name {
    padding-top: 10px;
  }
  .home-slider.style1 .title {
    font-size: 24px;
    line-height: 34px;
    margin: 0 auto 10px;
  }
  .home-slider.style1 p,
  .pricingtable-wrapper.style2 p {
    font-size: 14px;
  }
  .dlab-bnr-inr.dlab-bnr-inr-md.home-banner1 {
    height: 450px;
  }
  .team-box-type .team-mamber-img {
    width: 100%;
    display: unset;
  }
  .video-infobx h2.title {
    font-size: 24px;
    margin-bottom: 10px;
    line-height: 34px;
  }
  .dlab-bnr-inr {
    height: 180px;
  }
  .box-about-list h2 {
    font-size: 24px;
    line-height: 38px;
  }
  .box-about-list .icon-bx-wraper {
    padding: 20px;
  }
  .box-about-list .icon-bx-wraper h4.dlab-tilte {
    font-size: 18px;
    line-height: 28px;
  }
  .about-progress .max-w700 {
    padding: 30px 0 10px;
  }
  .chosesus-content h3 {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 30px;
  }
  .about-8-service .section-head .title-box {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 10px;
  }
  .service-main-title .title {
    font-size: 22px;
  }
  .dz_error-405 .dz_error {
    font-size: 100px;
    line-height: 100px;
  }
  .dz_error-405 .text-center {
    margin-bottom: 15px;
  }
  .dz_error-405 .error-content,
  .inner-text p,
  .inner-text ul li,
  .testimonial-1 .testimonial-text p,
  blockquote {
    font-size: 16px;
    line-height: 28px;
  }
  .testimonial-1 .testimonial-name {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0;
  }
  .testimonial-10 .testimonial-pic {
    height: 100px;
    width: 100px;
  }
  .testimonial-10 {
    padding-left: 120px;
  }
  .dlab-tabs .nav-tabs > li > a .title-head {
    display: none;
  }
  .dlab-tabs.vertical .nav-tabs {
    width: 60px;
  }
  .dlab-tabs.vertical .tab-content {
    margin-left: 59px;
  }
  .dlab-tabs.vertical.right .tab-content {
    margin-right: 59px;
  }
  .sort-title {
    margin-bottom: 25px;
  }
  .add-box h2,
  .item-add.add-style-2 h2,
  .item-add.add-style-3 h2 {
    font-size: 24px;
    line-height: 34px;
  }
  .item-add .add-box h5,
  .item-add.add-style-2 h5,
  .item-add.add-style-3 h5 {
    font-size: 16px;
    margin-bottom: 5px;
  }
}

.frame-box {
  background-image: url(../images/background/bg12.jpg);
}

.area-frame {
  margin-bottom: 100px;
}

.frame-box .site-footer,
.frame-box h1,
.frame-box h2,
.frame-box h3,
.frame-box h4,
.frame-box h5,
.frame-box h6 {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
}

.frame-box p {
  font-family: Poppins, sans-serif;
}

.frame-box .area-frame .container {
  background: #fff;
  position: relative;
  padding: 30px;
}

.frame-box .area-frame .container:after {
  content: "";
  background-image: url(../images/background/border-bg-top.png),
    url(../images/background/border-bg-bottom.png),
    url(../images/background/border-bg-left.png),
    url(../images/background/border-bg-right.png);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-position: top, bottom, left, right;
  height: calc(100% + 44px);
  position: absolute;
  width: calc(100% + 44px);
  left: -22px;
  top: -22px;
  z-index: -1;
  display: block;
}

.frame-box .page-wraper {
  background: rgba(0, 0, 0, 0);
}

.frame-box .page-content {
  padding-bottom: 0;
}

.frame-box .icon-bx-xs {
  background: #fff;
}

.frame-box .site-header.center .container > .logo-header {
  position: absolute;
  transform: translate(-50%);
  -moz-transform: translate(-50%);
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -o-transform: translate(-50%);
  position: absolute;
  left: 50%;
  text-align: center;
  top: 25px;
  z-index: 1;
}

.frame-box .site-header.center .logo-header a {
  display: block;
}

.frame-box .header-nav .nav > li:hover > a {
  background-color: var(--color-primary);
  color: #fff !important;
}

.frame-box .header-nav .nav > li.active > a,
.frame-box .header-nav .nav > li > a:hover {
  background-color: var(--color-secondry);
}

.frame-box .header-nav .nav > li > a {
  color: #fff;
  font-size: 15px;
  padding: 12px 20px;
  cursor: pointer;
  font-weight: 600;
  display: inline-block;
  background: var(--color-primary);
  margin: 0 2px;
  border-radius: 4px;
  font-family: "Roboto Condensed", sans-serif;
}

.frame-box .main-bar {
  width: 100%;
  position: relative;
  padding: 65px 0;
  background-color: rgba(0, 0, 0, 0);
}

.frame-box .top-bar {
  position: relative;
  background: #fff;
  font-size: 15px;
  padding: 8px 0 2px 0;
}

.frame-box .top-bar:after {
  content: "";
  background-image: url(../images/background/border-bg-bottom.png);
  position: absolute;
  bottom: -22px;
  width: 100%;
  height: 22px;
  left: 0;
}

.frame-box .topbar-social ul {
  display: inline-block;
}

.frame-box .topbar-social li a {
  color: #fff;
}

.frame-box .is-fixed .main-bar {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 10px 0;
}

.frame-box .site-header.center .is-fixed .container > .logo-header {
  background: var(--color-primary);
  padding: 8px 15px 8px;
  top: 0;
  height: 90px;
  width: 125px;
  border-radius: 0 0 15px 15px;
}

.frame-box .header-nav .nav > li.active > a:after,
.frame-box .header-nav .nav > li:hover > a:after {
  content: none;
}

.frame-box .header-nav .logo-header,
.frame-box .is-fixed .logo-header .logo1,
.frame-box .logo-header .logo2 {
  display: none;
}

.frame-box .site-header .main-bar .container {
  position: static;
}

.frame-box .is-fixed .logo-header .logo2,
.frame-box .logo-footer > a {
  display: block;
}

.frame-box .site-header.center .logo-header img {
  height: auto;
  position: relative;
  width: 100%;
  z-index: 2;
}

.frame-box .site-header.center .is-fixed .container > .logo-header img {
  height: auto;
  width: 100%;
}

.frame-box .logo-header {
  width: 150px;
  height: 150px;
}

.frame-box .slide-content-box .slider-title {
  font-size: 50px;
  color: #fff;
  line-height: 64px;
  margin-bottom: 10px;
  font-weight: 600;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}

.frame-box .slide-content-box p {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 21px;
  font-weight: 400;
  color: #fff;
  margin: 0 auto;
  line-height: 30px;
}

.frame-box .slide-content-area {
  max-width: 650px;
  padding-left: 100px;
}

.frame-box .slide-content-box {
  position: relative;
  top: 50%;
  text-align: left;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transition: all 1s ease 0.5s;
  -webkit-transition: all 1s ease 0.5s;
  -o-transition: all 1s ease 0.5s;
  -moz-transition: all 1s ease 0.5s;
}

.frame-box .owl-slider.owl-theme .owl-dots .owl-dot.active span {
  background: var(--color-primary);
}

.frame-box .owl-slider.owl-theme .owl-dots .owl-dot span {
  width: 16px;
  height: 16px;
  background: 0 0;
  border: 3px solid #fff;
  margin: 5px 5px;
}

.frame-box .owl-slider.owl-theme .owl-dots {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px 0;
}

.frame-box .owl-slider {
  overflow: hidden;
  border-radius: 6px;
}

.frame-box .about-box {
  padding: 10px;
  border: 2px dashed;
  border-radius: 14px;
  background: #fff;
}

.frame-box .about-bx strong {
  font-weight: 700;
  text-transform: uppercase;
}

.frame-box .project-bx {
  background-image: url(../images/pattern/pt1.jpg);
  padding: 10px;
  border-radius: 10px;
  height: 100%;
}

.frame-box .project-bx .media {
  overflow: hidden;
  border-radius: 8px;
}

.frame-box .project-bx .info .title {
  font-weight: 600;
  margin: 0;
}

.frame-box .project-bx .info {
  padding: 10px 20px 5px 15px;
}

.frame-box .project-bx .info .title a {
  color: var(--color-primary);
}

.frame-box .owl-project .owl-stage {
  display: flex;
  flex-wrap: wrap;
}

.frame-box .owl-project .owl-stage .item {
  height: 100%;
}

.frame-box .service-list li:after {
  content: "\f00c";
  font-family: FontAwesome;
  background-color: var(--color-primary);
  width: 45px;
  height: 45px;
  color: #fff;
  position: absolute;
  text-align: center;
  line-height: 45px;
  border-radius: 45px;
  left: 0;
  top: 15px;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.frame-box .service-list li:hover:after {
  background-color: var(--color-hover);
}

.frame-box .service-list li {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: #000;
  padding-left: 60px;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 78px;
  display: flex;
  align-items: center;
}

.frame-box .testimonial-text p {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 24px;
  color: #6c6979;
  line-height: 40px;
}

.frame-box .item .testimonial-1 .testimonial-position {
  color: #f36523;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
}

.frame-box .testimonial-1 .testimonial-name {
  font-family: "Roboto Condensed", sans-serif;
  color: #000;
  font-size: 21px;
  margin-top: 5px;
}

.frame-box .client-carousel .owl-nav .owl-prev {
  left: 70px;
}

.frame-box .client-carousel .owl-nav .owl-next {
  right: 70px;
}

.frame-box .gallery {
  margin: 0;
  padding: 0;
  list-style: none;
}

.frame-box .gallery .dlab-box {
  overflow: hidden;
  border-radius: 8px;
}

.frame-box .gallery.video-gallery .dlab-box .overlay-bx {
  visibility: visible;
  opacity: 1;
}

.frame-box .gallery.video-gallery .dlab-box .overlay-bx i {
  background: #fff;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 35px;
  padding: 0 0 0 4px;
}

.frame-box .gallery-bx > div:last-child {
  padding-left: 30px;
}

.frame-box .gallery-bx > div:first-child {
  padding-right: 30px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.frame-box .ft-contact {
  background-color: transparent;
  display: block;
}

.frame-box .footer-top {
  background: #282828;
  background-size: cover;
  background-position: center;
  padding: 80px 0 30px;
  position: relative;
}

.frame-box .footer-top:after {
  content: "";
  background-image: url(../images/background/border-bg-dark-top.png);
  position: absolute;
  top: -22px;
  width: 100%;
  height: 22px;
  left: 0;
}

.frame-box .footer-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 40px;
  color: #fff;
  padding-bottom: 15px;
  position: relative;
}

.frame-box .footer-title:after {
  content: "";
  width: 60px;
  height: 3px;
  background: var(--color-primary);
  position: absolute;
  bottom: 0;
  left: 0;
}

.frame-box .widget_getintuch i {
  position: absolute;
  left: 0;
  top: 5px;
  text-align: center;
  font-size: 18px;
  width: 40px;
  height: 40px;
  background: #363636;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  line-height: 38px;
  color: #fff;
}

.frame-box .ft-social li {
  display: inline-block;
}

.frame-box .ft-contact li {
  color: #fff;
  padding: 5px 0;
}

.frame-box .footer-bottom {
  background-color: #282828;
  padding: 20px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.frame-box footer .footer-bottom,
.frame-box footer li,
.frame-box footer p {
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
}

.frame-box footer a,
.frame-box footer a:visited,
.frame-box footer ul span {
  color: #aaa;
}

.frame-box .ft-social .site-button {
  color: #fff;
}

.frame-box .widget_getintuch li {
  margin-bottom: 20px;
  position: relative;
  line-height: 28px;
  padding-left: 55px;
}

.header-white-transparent {
  background-color: rgba(255, 255, 255, 0.3);
}

.cargo-banner {
  height: 100vh;
  text-align: left;
}

.cargo-banner .title {
  font-size: 55px;
  font-weight: 600;
  line-height: 70px;
  margin-bottom: 30px;
}

.cargo-banner .dlab-bnr-inr-entry {
  display: table-cell;
  vertical-align: bottom;
}

.banner-form {
  border-bottom: 5px solid rgba(255, 255, 255, 0.3);
}

.banner-form .bootstrap-select .dropdown-toggle,
.banner-form .form-control,
.banner-form .site-button {
  border-radius: 4px;
  height: 55px;
  padding: 14px 20px;
  font-size: 15px;
}

.banner-form .bootstrap-select.btn-group .dropdown-toggle .caret {
  font-size: 15px;
  top: 22px;
  right: 20px;
}

.cargo-banner .bottom-link {
  padding: 25px 0 60px 0;
}

.cargo-banner .bottom-link h4 {
  margin-bottom: 0;
  font-weight: 500;
}

.service-box.style5 {
  background-color: #fff;
}

.service-box.style5 .icon-content {
  padding: 20px 30px;
  display: flex;
  align-items: center;
}

.service-box.style5 .dlab-tilte {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  margin-bottom: 0;
}

.service-box.style5 .icon-content .link-btn {
  background-color: var(--color-primary);
  width: 50px;
  height: 50px;
  border-radius: 50px;
  max-width: 50px;
  min-width: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 30px;
  color: #fff;
  font-weight: 300;
  margin-left: auto;
}

.service-box.style6 .icon-bx {
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 0;
  z-index: 99;
}

.service-box.style6 .icon-bx:after {
  height: 100%;
  content: "";
  width: 100%;
  right: -10px;
  bottom: 0;
  position: absolute;
  z-index: -1;
  transform: skew(19deg);
}

.service-box.style6 .icon-bx.bg-primary:after {
  background-color: var(--color-primary);
}

.service-box.style6 .icon-bx.icon-bx-sm {
  width: 60px;
  height: 60px;
  line-height: 65px;
  text-align: center;
}

.service-box.style6 .dlab-tilte {
  margin-bottom: 10px;
}

@media only screen and (max-width: 1200px) {
  .cargo-banner .title {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 30px;
  }
  .cargo-banner .bottom-link {
    padding: 30px 0;
  }
  .service-box.style5 .icon-content {
    padding: 30px;
  }
  .service-box.style5 h2.dlab-tilte {
    font-size: 24px;
    line-height: 36px;
  }
}

@media only screen and (max-width: 991px) {
  .cargo-banner .title {
    font-size: 40px;
    line-height: 50px;
  }
  .banner-form .bootstrap-select .dropdown-toggle,
  .banner-form .form-control,
  .banner-form .site-button {
    height: 50px;
    padding: 10px 15px;
    font-size: 16px;
  }
  .banner-form {
    padding-bottom: 10px;
  }
  .cargo-banner .bottom-link {
    padding: 20px 0;
  }
  .cargo-banner .dlab-bnr-inr-entry {
    padding-bottom: 0;
  }
  .filters2 ul li a i {
    font-size: 30px;
    line-height: 30px;
  }
  .filters2 ul li a {
    font-size: 18px;
  }
  .filters2 ul {
    display: flex;
    flex-wrap: wrap;
  }
  .filters2 ul li {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    min-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .portbox3:hover .overlay-bx {
    padding: 20px;
  }
  .portbox3 .portinner .port-up h3.port-title {
    font-size: 18px;
    line-height: 28px;
  }
  .portbox3 .portinner .port-up span {
    font-size: 12px;
  }
}

@media only screen and (max-width: 591px) {
  .cargo-banner .title {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .cargo-banner .bottom-link h4 {
    font-size: 16px;
  }
  .cargo-banner .bottom-link {
    padding: 15px 0;
  }
  .cargo-banner .banner-form .row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .cargo-banner .banner-form [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
  }
  .form-group {
    margin-bottom: 10px;
  }
  .service-box.style5 h2.dlab-tilte {
    font-size: 22px;
    line-height: 32px;
  }
  .service-box.style5 .icon-content {
    padding: 25px;
  }
}

.header-curve.ext-header .middle-bar.bg-dark .service-list ul li .title {
  color: #fff;
}

.header-curve.ext-header .extra-nav:after,
.header-curve.ext-header .extra-nav:before,
.header-curve.ext-header .logo-header:after,
.header-curve.ext-header .logo-header:before,
.social-curve:after,
.social-curve:before {
  background-color: var(--color-primary);
}

.header-curve.ext-header .logo-header:before {
  -webkit-transform: skew(-30deg);
  -moz-transform: skew(-30deg);
  -o-transform: skew(-30deg);
  -ms-transform: skew(-30deg);
  transform: skew(-30deg);
  right: -140px;
  width: 100%;
}

.header-curve.ext-header .middle-bar {
  padding: 0;
  border-bottom: 0;
}

.header-curve.ext-header .middle-bar .logo-header {
  padding: 20px 0;
}

.header-curve.ext-header .container-fluid {
  padding-left: 50px;
  padding-right: 50px;
}

.header-curve.ext-header .extra-nav {
  position: relative;
  width: 20%;
  top: 1px;
}

.header-curve.ext-header .extra-nav:before,
.social-curve:before {
  -webkit-transform: skew(30deg);
  -moz-transform: skew(30deg);
  -o-transform: skew(30deg);
  -ms-transform: skew(30deg);
  transform: skew(30deg);
}

.header-curve.ext-header .extra-nav:after,
.header-curve.ext-header .extra-nav:before,
.social-curve:after,
.social-curve:before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 100%;
  z-index: -1;
}

.header-curve.ext-header .extra-nav:before {
  width: 100%;
  left: -25px;
}

.header-curve.ext-header .extra-nav:after {
  width: 100%;
  right: -50px;
}

.header-curve.ext-header .extra-nav .site-button-link {
  color: #fff;
  font-size: 14px;
}

.header-curve.ext-header .header-nav .nav > li.active > a,
.header-curve.ext-header .header-nav .nav > li.current-menu-item > a {
  background-color: transparent;
}

.social-curve {
  position: relative;
  padding: 20px 30px;
  width: 100%;
  margin: 0 0 -15px;
}

.social-curve:before {
  width: 100%;
  left: -50%;
  z-index: 0;
}

.social-curve .social-icon li a {
  color: #fff !important;
  font-size: 18px;
}

.social-curve .social-icon {
  z-index: 99;
  position: relative;
}

.social-curve .social-icon .title {
  color: #fff;
  font-size: 18px;
  text-transform: capitalize;
  letter-spacing: 1px;
}

.header-curve.ext-header.style2 .logo-header:after,
.header-curve.ext-header.style2 .logo-header:before {
  content: unset;
}

.header-curve.ext-header.style2 .extra-nav {
  height: 65px;
  top: 0;
  transform: unset;
}

.header-curve.ext-header.style2 .extra-nav:before {
  -webkit-transform: skew(-30deg);
  -moz-transform: skew(-30deg);
  -o-transform: skew(-30deg);
  -ms-transform: skew(-30deg);
  transform: skew(-30deg);
}

.header-curve.ext-header.style2.white .header-nav .nav > li.active > a {
  color: var(--color-primary) !important;
}

.slider-tabs .slider-box {
  position: relative;
}

.slider-tabs .slider-box .slider-content p {
  color: #fff;
  font-size: 18px;
  font-family: Teko, sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 30px;
  margin-bottom: 35px;
  max-width: 700px;
}

.slider-box .slider-content {
  position: absolute;
  z-index: 99;
  left: 50%;
  top: 50%;
  width: 1170px;
  transform: translate(-50%, -50%);
}

.slider-tabs .slider-box .slider-content .title {
  font-size: 70px;
  font-family: Teko, sans-serif;
  line-height: 70px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #fff;
  text-transform: uppercase;
}

.slider-tabs .slide-tabs {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px 10px;
}

.slider-tabs .dlab-thum-bx img {
  height: 100vh;
  object-fit: cover;
}

.slider-tabs .tab-box .content-box .title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: #000;
  margin-bottom: 4px;
  text-transform: capitalize;
  letter-spacing: 1px;
}

.slider-tabs .tab-box .content-box p {
  color: #000;
  margin-bottom: 0;
  opacity: 0.85;
  font-weight: 400;
  font-size: 14px;
}

.slider-tabs .tab-box .icon-box i {
  font-size: 35px;
  line-height: 35px;
  color: #000;
}

.slider-tabs .tab-box {
  position: relative;
  padding: 15px 15px 15px 80px;
  background-color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  cursor: pointer;
}

.slider-tabs .tab-box .icon-box {
  position: absolute;
  left: 20px;
  top: 25px;
}

.slider-tabs .owl-item.current.active .tab-box {
  background: var(--color-primary);
  color: #000;
}

.slider-tabs .owl-item.current.active .tab-box .content-box .title,
.slider-tabs .owl-item.current.active .tab-box .content-box p,
.slider-tabs .owl-item.current.active .tab-box .icon-box i {
  color: #fff;
}

.service-box-4 .service-images {
  position: relative;
  padding: 20px 20px 0;
}

.service-box-4 .service-images img {
  width: 220px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 30px 0;
  border-radius: 500px;
  border-width: 10px;
  border-style: solid;
  border-color: #fff;
  border-image: initial;
  transition: all 0.5s ease 0s;
}

.service-box-4:hover .service-images img {
  border-width: 10px;
  border-style: solid;
  border-color: var(--color-primary);
  border-image: initial;
}

.service-box-4 .service-images i {
  font-size: 40px;
  width: 70px;
  height: 70px;
  border-radius: 40px;
  position: absolute;
  bottom: 10px;
  line-height: 70px;
  right: 10px;
  background: #fff;
  color: var(--color-primary);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.service-box-4 .service-content .title {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 5px;
}

.section-head.style1 .title {
  font-size: 50px;
  font-weight: 600;
  line-height: 60px;
  font-family: poppins;
  color: #000;
}

.about-info1 p,
.section-head.style1 p {
  color: #768492;
  line-height: 28px;
  font-size: 16px;
}

.latest-project-info.style1 {
  padding: 70px 70px 10px;
}

.latest-project-info.style1 .list-check li p {
  font-size: 16px;
}

.latest-project-info.style1 .list-check li:before {
  top: 20px;
}

.video-bx.radius-no {
  border-radius: 0;
}

.dlab-services-box {
  padding: 50px;
}

.service-year {
  font-size: 130px;
  line-height: 140px;
  font-weight: 600;
  margin-bottom: 0;
}

.service-year small {
  font-size: 22px;
  line-height: 22px;
  text-transform: uppercase;
  font-weight: 600;
}

.request-form {
  margin-top: -178px;
}

.request-form form {
  background-color: #fff;
  padding: 30px 30px 20px;
  box-shadow: 0 0 41px 5px rgba(0, 0, 0, 0.1);
}

.request-form .request-form-header {
  background-color: var(--color-primary);
  padding: 20px 50px 20px 100px;
  position: relative;
}

.request-form .request-form-header i {
  position: absolute;
  left: 30px;
  font-size: 45px;
  top: 14px;
  color: #fff;
}

.request-form .request-form-header p {
  color: #fff;
  margin-bottom: 0;
  font-size: 14px;
}

.request-form .request-form-header h2 {
  color: #fff;
  font-size: 24px;
  margin-bottom: 0;
  line-height: 30px;
}

.request-form label {
  position: absolute;
  font-size: 16px;
  font-weight: 400;
  color: #606060;
  top: 5px;
  left: 0;
  transition: all 0.5s;
  font-family: Poppins, sans-serif;
  text-transform: capitalize;
}

.request-form .form-control {
  background-color: transparent;
  border-width: 0;
  border-bottom-color: #771cb8;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  position: relative;
  padding: 15px 0;
}

.request-form .input-group:after {
  width: 100%;
  height: 2px;
  content: "";
  border-radius: 0;
  box-shadow: none;
  bottom: 0;
  background: var(--color-primary);
  display: block;
}

.request-form .focused label {
  font-size: 12px;
  top: -10px;
  left: 0;
  color: #707070;
}

.dlab-box.project-bx.dlab-team2 .dlab-info.bg-dark {
  background-color: #1f0404;
}

.dlab-box.project-bx.dlab-team2 .dlab-info.bg-dark .dlab-title a {
  color: #fff;
}

.header-sidenav .full-sidenav {
  position: fixed;
  right: -300px;
  width: 300px;
  top: 0;
  z-index: 999988;
  background: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  left: auto;
}

.header-sidenav .full-sidenav .mCSB_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}

.header-sidenav .full-sidenav.active {
  right: 0;
}

.header-sidenav .full-sidenav .nav {
  margin-bottom: auto;
}

.header-sidenav .header-nav.full-sidenav .nav > li > a {
  color: #000;
}

.header-sidenav .header-nav.full-sidenav .nav > li > a:after {
  content: none;
}

.header-sidenav .header-nav.full-sidenav .logo-header,
.header-sidenav .header-nav.full-sidenav .nav,
.header-sidenav .header-nav.full-sidenav .social-menu {
  float: none;
  width: 100%;
}

.header-sidenav .header-nav.full-sidenav .logo-header {
  height: auto;
  padding: 50px 40px 30px 40px;
}

.header-sidenav.header-nav.full-sidenav .logo-header a {
  width: 170px;
  display: block;
}

.header-sidenav .header-nav.full-sidenav .nav > li:hover > .mega-menu,
.header-sidenav .header-nav.full-sidenav .nav > li:hover > .sub-menu,
.header-sidenav .header-nav.full-sidenav .nav > li > .mega-menu,
.header-sidenav .header-nav.full-sidenav .nav > li > .sub-menu {
  opacity: 1;
  visibility: visible;
  position: unset;
}

.header-sidenav .header-nav.full-sidenav .nav > li > .mega-menu,
.header-sidenav .header-nav.full-sidenav .nav > li > .sub-menu {
  box-shadow: none;
  -webkit-transition: unset;
  -moz-transition: unset;
  -ms-transition: unset;
  -o-transition: unset;
  transition: unset;
}

.header-sidenav .header-nav .nav > li .sub-menu {
  width: 100%;
  padding: 0;
}

.header-sidenav .header-nav .nav > li .sub-menu li a {
  color: #8799a3;
  padding: 10px 40px;
}

.header-sidenav .header-nav .nav > li > a {
  padding: 12px 40px;
  width: 100%;
  font-size: 18px;
}

.header-sidenav .social-menu {
  padding: 40px;
}

.header-sidenav .social-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 15px;
}

.header-sidenav .social-menu .copyright-head {
  margin-bottom: 0;
  color: #ccc;
}

.header-sidenav .social-menu ul li {
  display: inline-block;
}

.header-sidenav .social-menu ul li a {
  color: #bbc7cd;
  font-size: 18px;
  padding-right: 15px;
}

.header-sidenav .social-menu ul li a:hover {
  color: #8799a3;
}

.header-sidenav .header-nav.full-sidenav .mCustomScrollBox {
  width: 100%;
}

.header-sidenav .menu-close i {
  color: #fff;
  font-size: 24px;
  position: absolute;
  right: 320px;
  top: 20px;
}

.header-sidenav .menu-close {
  position: fixed;
  height: 100vh;
  width: 0;
  background: #3a3a3a;
  top: 0;
  right: 0;
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  filter: blur(300px);
  cursor: pointer;
  overflow: hidden;
  z-index: 9999;
}

.header-sidenav .menu-close.active {
  width: 100%;
  opacity: 0.8;
  filter: blur(0);
}

.header-sidenav .header-transparent .is-fixed .main-bar {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.9);
}

.header-sidenav .header-full .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

.header-sidenav .site-header ul,
.site-header ol {
  margin-bottom: 0;
  list-style: none;
}

.header-sidenav .extra-nav ul li {
  list-style: none;
}

.header-sidenav .extra-nav,
.header-sidenav .is-fixed .extra-nav {
  background-color: var(--color-primary);
  margin: 0;
  padding: 0;
}

.header-sidenav .extra-nav .extra-cell {
  margin: 0;
}

.header-sidenav .menu-icon {
  padding: 0;
  width: 70px;
  height: 71px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-sidenav .menu-icon-in {
  width: 30px;
  height: 22px;
  position: relative;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  display: inline-block;
}

.header-sidenav .menu-icon-in span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  background-color: #fff;
  border-radius: 10px;
}

.header-sidenav .menu-icon-in span:nth-child(1) {
  top: 0;
  width: 60%;
}

.header-sidenav .menu-icon-in span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.header-sidenav .menu-icon-in span:nth-child(3) {
  bottom: 0;
  width: 85%;
}

.header-sidenav .menu-icon:hover .menu-icon-in span:nth-child(1),
.header-sidenav .menu-icon:hover .menu-icon-in span:nth-child(2),
.header-sidenav .menu-icon:hover .menu-icon-in span:nth-child(3) {
  width: 100%;
}

.header-sidenav.header-transparent .main-bar {
  background-color: transparent;
  border: 0;
}

.header-sidenav.header-transparent .is-fixed .main-bar {
  background-color: rgba(0, 0, 0, 0.9);
}

.header-sidenav .header-nav.full-sidenav .nav > li > a.scroll.active {
  color: var(--color-primary);
}

.dlab-team3 {
  background: #fff;
  padding: 10px 10px;
  border-radius: 4px;
}

.dlab-team3 .dlab-info {
  text-align: center;
  padding: 20px 0;
}

.dlab-team3 .dlab-info .dlab-title {
  font-size: 18px;
  margin-bottom: 5px;
}

.dlab-team3 .dlab-info .dlab-position {
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: 600;
  color: #000;
  opacity: 0.3;
  margin-bottom: 10px;
}

.dlab-team3 .dlab-info .dlab-social-icon li a {
  width: 30px;
  height: 30px;
  background: #000;
  font-size: 12px;
  line-height: 30px;
  padding: 0;
  border: 0;
  border-radius: 40px;
  color: #fff;
}

.dlab-team11 .dlab-media {
  width: 240px;
  height: 240px;
  margin: auto;
  border-radius: 240px;
  overflow: hidden;
  padding: 10px;
  border: 2px dashed;
}

.dlab-team11 .dlab-info {
  background: var(--color-primary);
  padding: 10px 15px;
  border-radius: 8px;
  margin-top: 15px;
  text-align: center;
}

.dlab-team11 .dlab-media img {
  border-radius: 100%;
}

.dlab-team11 .dlab-title {
  margin: 0;
}

.dlab-team11 .dlab-title a {
  color: #fff;
}

.dlab-team11 .dlab-position {
  color: #fff;
  text-transform: capitalize;
}

.dlab-team11-area.dots-theme.owl-theme .owl-dots .owl-dot span {
  width: 16px;
  height: 16px;
  background: 0 0;
  border: 3px solid #bcbcbc;
  margin: 5px 5px;
}

.dlab-team11-area.dots-theme.owl-theme .owl-dots .owl-dot.active span {
  border: 3px solid var(--color-primary);
}

.testimonial-1.testimonial-box {
  background: #fff;
  text-align: left;
  padding: 20px 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  margin: 30px 0;
}

.testimonial-1.testimonial-box .testimonial-detail {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px 30px 4px 30px;
  margin: 20px -30px 0 -30px;
  position: relative;
}

.testimonial-1.testimonial-box .review li {
  display: inline-block;
  color: #ffc000;
}

.testimonial-1.testimonial-box .testimonial-text {
  padding: 0;
}

.testimonial-1.testimonial-box .testimonial-text p {
  font-size: 16px;
  line-height: 28px;
}

.testimonial-1.testimonial-box .review {
  top: 20px;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  right: 30px;
}

.testimonial-1.testimonial-box .testimonial-name {
  font-size: 22px;
  color: #000 !important;
  margin-bottom: 5px;
}

.hex-box .hex {
  fill: transparent;
  stroke: var(--color-primary);
  stroke-width: 3;
}

.hex-box .icon-box {
  position: relative;
}

.hex-box .icon-box i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 36px;
  height: 70px;
}

.btn.youtube.outline {
  color: #ce201f;
}

.h100 {
  height: 100%;
}

.header-nav .nav > li.homedemo .mega-menu {
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  border-radius: 0;
}

.header-nav .nav > li.homedemo .mega-menu > li {
  flex: 0 0 20%;
  max-width: 25%;
}

.header-nav .nav > li.homedemo .mega-menu > li:after {
  content: none;
}

.header-nav .nav > li.homedemo .mega-menu > li > a {
  border: 0;
  padding-bottom: 0;
}

.header-nav .nav > li.homedemo .mega-menu > li > a img {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.17);
  margin-bottom: 15px;
  transition: all 0.5s;
  width: 100%;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.header-nav .nav > li.homedemo .mega-menu > li:hover > a img {
  transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
}

.header-nav .nav > li.homedemo .mega-menu > li > a span {
  display: block;
  text-transform: capitalize;
  font-size: 16px;
}

.header-nav .nav > li.homedemo .mega-menu > li:nth-child(2n) {
  background: rgba(0, 0, 0, 0.04);
}

.header-nav .dlab-social-icon {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .frame-box .header-nav .nav > li > a {
    font-size: 14px;
    padding: 10px 16px;
    margin: 0 1px;
  }
  .header-curve.ext-header .logo-header:before {
    right: -50px;
  }
  .header-curve.ext-header .service-list ul li i {
    left: 7px;
    font-size: 30px;
    line-height: 38px;
  }
  .header-curve.ext-header .service-list ul li {
    padding: 0 0 0 45px;
    margin-left: 10px;
  }
  .header-curve.ext-header .service-list ul li .title {
    font-size: 16px;
  }
  .header-curve.ext-header .extra-nav:before {
    left: -5px;
  }
  .header-curve.ext-header .extra-nav .extra-cell {
    float: right;
  }
  .service-box-4 .service-images img {
    width: 100%;
  }
  .request-form .request-form-header {
    padding: 20px 30px 20px 80px;
  }
  .request-form .request-form-header h2 {
    font-size: 20px;
    line-height: 26px;
  }
  .request-form .request-form-header i {
    font-size: 36px;
  }
  .request-form {
    margin-top: -174px;
  }
  .list-check.list-2.rounded.border[class*="list-"] li {
    padding: 10px 20px 10px 45px;
  }
}

@media only screen and (max-width: 991px) {
  .header-curve.ext-header .extra-nav:after,
  .header-curve.ext-header .extra-nav:before {
    content: none;
  }
  .header-sidenav .header-nav.full-sidenav .nav > li > a {
    padding: 8px 0;
    border: 0;
  }
  .header-sidenav .header-nav.full-sidenav .logo-header a {
    text-align: left;
  }
  .header-sidenav .header-nav.full-sidenav .logo-header {
    padding: 30px 40px 30px 28px;
  }
  .header-sidenav .social-menu {
    padding: 10px 30px 20px 30px;
  }
  .header-nav .dlab-social-icon.ind-social ul {
    margin-bottom: 5px;
  }
  .header-nav .dlab-social-icon.ind-social li a {
    color: #111;
  }
  .header-nav .dlab-social-icon.ind-social p {
    color: #111;
  }
  .header-nav .dlab-social-icon {
    display: block;
    margin: 0;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .header-nav .dlab-social-icon li {
    color: #000;
    font-size: 16px;
    margin: 0 2px;
  }
  .ft-contact .ft-contact-bx {
    padding: 20px 20px 20px 75px;
  }
  .ft-contact .ft-contact-bx img {
    width: 35px;
    height: 35px;
    left: 25px;
  }
  .ft-contact .ft-contact-bx p {
    line-height: 26px;
    font-size: 14px;
  }
  .ft-contact .ft-contact-bx .title {
    margin: 0 0 5px 0;
    font-size: 18px;
    line-height: 24px;
  }
  .project-list {
    margin-bottom: 0;
  }
  .about-year span {
    font-size: 70px;
    width: 110px;
    height: 120px;
  }
  .section-head.style2 .title {
    font-size: 30px;
  }
  .about-year {
    margin-bottom: 15px;
  }
  .const-about .section-head {
    margin-bottom: 30px;
  }
  .project-info-box .project-content {
    padding: 15px 20px 10px;
    width: 350px;
  }
  .project-info-box .list-details li {
    padding: 8px 0;
    font-size: 14px;
  }
  .latest-project-info {
    padding: 50px;
  }
  .social-curve:before {
    left: -40%;
  }
  .header-curve.ext-header .extra-nav:after {
    right: -100px;
  }
  .header-curve.ext-header .extra-nav {
    width: 30%;
  }
  .header-curve.ext-header .navbar-toggler {
    z-index: 99;
    position: relative;
  }
  .frame-box .slide-content-area {
    max-width: 500px;
    padding-left: 50px;
  }
  .frame-box .slide-content-box .slider-title {
    font-size: 36px;
    line-height: 46px;
  }
  .frame-box .slide-content-box p {
    font-size: 18px;
    line-height: 28px;
  }
  .frame-box .site-header.center .logo-header img {
    height: 100px;
    width: 100px;
  }
  .frame-box .site-header.center .navbar-toggler {
    margin: 16px 0 18px 15px;
  }
  .frame-box .main-bar {
    padding: 55px 0;
  }
  .frame-box .site-header.center .navbar-nav > li {
    display: block;
    float: left;
    width: 100%;
  }
  .frame-box .header-nav .nav > li > a {
    background-color: #fff;
    color: #000;
    display: block;
    padding: 8px 0;
    border-radius: 0;
    font-size: 18px;
    font-family: Roboto;
    font-weight: 500;
  }
  .frame-box .header-nav .nav > li:hover > a {
    border-radius: 0;
    background-color: transparent;
    color: #000 !important;
  }
  .frame-box .header-nav .nav > li.active > a,
  .frame-box .header-nav .nav > li > a:hover {
    background-color: transparent;
    color: #000 !important;
    border-radius: 0;
  }
  .frame-box .mo-left .header-nav .logo-header img {
    height: auto;
    width: 150px;
  }
  .frame-box .mo-left .header-nav .logo-header {
    display: block;
    float: none;
    height: 90px;
    max-width: 100%;
    padding: 20px 15px 20px;
    width: 100%;
    position: unset;
    left: 0;
    transform: unset;
  }
  .frame-box .site-header.center .is-fixed .container > .logo-header {
    z-index: 0;
  }
  .header-transparent.box-header .navbar-toggler span {
    background-color: #000;
  }
  .header-transparent.box-header .navbar-toggler {
    margin: 26px 0 18px 35px;
  }
  .box-header .header-nav .nav > li > a {
    font-family: Roboto;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .request-form {
    margin-top: 0;
  }
  .latest-project-info.style1 {
    padding: 50px 50px 0;
  }
  .header-nav .nav > li.homedemo .mega-menu {
    margin: 0;
    padding: 10px 0;
  }
  .header-nav .nav > li.homedemo .mega-menu > li > a {
    display: block;
    padding: 5px 0 5px 15px;
    position: relative;
  }
  .header-nav .nav > li.homedemo .mega-menu > li > a:after {
    content: "\f105";
    font-family: FontAwesome;
    width: auto;
    height: auto;
    background: 0 0;
    top: 3px;
    color: #000;
    font-size: 18px;
    position: absolute;
    left: 0;
    font-weight: 700;
  }
  .header-nav .nav > li.homedemo .mega-menu,
  .header-nav .nav > li.homedemo .mega-menu > li > a img {
    display: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .header-nav .nav > li.homedemo.open .mega-menu {
    display: block;
  }
  .header-nav .nav > li.homedemo .mega-menu > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .header-nav .nav > li.homedemo .mega-menu > li > a span {
    display: block;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 400;
    color: #505050;
  }
  .header-nav .nav > li.homedemo .mega-menu > li:nth-child(2n) {
    background: 0 0;
  }
  .frame-box .top-bar {
    padding: 8px 0 8px 0;
  }
}

@media only screen and (max-width: 767px) {
  .header-sidenav .menu-icon-in span {
    height: 2px;
  }
  .header-sidenav .menu-icon-in {
    width: 20px;
    height: 16px;
  }
  .header-sidenav .menu-icon {
    width: 50px;
    height: 50px;
  }
  .contact-no-area .title {
    font-size: 36px;
    line-height: 50px;
    margin-bottom: 20px;
  }
  .counter-style-5 {
    padding: 30px 15px;
  }
  .counter-style-5 .counter {
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 5px;
  }
  .counter-staus-box .section-head .title {
    font-size: 36px;
    line-height: 46px;
  }
  .counter-style-5 p {
    font-size: 14px;
  }
  .counter-style-5 .icon-xl i {
    font-size: 50px;
    line-height: 50px;
  }
  .counter-staus-box .play-btn {
    margin-bottom: 20px;
  }
  .service-media-bx .dlab-info {
    padding: 25px 0 10px 0;
  }
  .service-media-bx {
    padding: 15px;
  }
  .ft-contact .ft-contact-bx:after {
    content: unset;
  }
  .ft-contact .ft-contact-bx {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 25px 20px 5px 75px;
  }
  .ft-contact {
    padding-bottom: 20px;
  }
  .project-slider .about-content {
    padding-top: 30px;
  }
  .project-slider:after {
    content: unset;
  }
  .project-info-box .project-media img {
    height: 300px;
    object-fit: cover;
  }
  .project-carousel-1 .owl-nav {
    right: 0;
    bottom: 10px;
  }
  .project-info-box .project-content {
    width: 300px;
  }
  .project-carousel-1 .owl-next,
  .project-carousel-1 .owl-prev {
    font-size: 14px;
    letter-spacing: 1px;
    margin: 0 10px;
  }
  .social-curve:before {
    left: -30%;
  }
  .header-curve.ext-header .extra-nav {
    width: 35%;
    padding: 11px 0;
  }
  .frame-box .slide-content-area {
    max-width: 100%;
    padding-left: 35px;
    padding-right: 100px;
  }
  .frame-box .slide-content-box .slider-title {
    font-size: 30px;
    line-height: 40px;
  }
  .frame-box .slide-content-box p {
    font-size: 16px;
    line-height: 26px;
  }
  .frame-box .area-frame .container {
    padding: 15px;
    border-radius: 10px;
  }
  .frame-box .area-frame .container:after,
  .frame-box .top-bar:after {
    content: unset;
  }
  .frame-box .main-bar {
    padding: 25px 0;
  }
  .frame-box .dlab-topbar-right {
    display: none;
  }
  .section-head.style1 .title {
    font-size: 36px;
    line-height: 46px;
  }
  .header-transparent.box-header .is-fixed .navbar-toggler,
  .header-transparent.box-header .navbar-toggler {
    margin: 17px 0 15px 20px;
  }
  .box-header .extra-nav .site-button,
  .box-header .is-fixed .extra-nav .site-button {
    height: 50px;
  }
  .box-header.site-header .extra-nav,
  .box-header.site-header .is-fixed .extra-nav {
    padding: 0;
  }
  .dlab-services-box {
    padding: 30px;
  }
  .service-year {
    font-size: 100px;
    line-height: 120px;
  }
  .latest-project-info.style1 {
    padding: 30px 30px 0;
  }
  .list-check.list-2.rounded.border[class*="list-"] li {
    width: 100%;
  }
}

@media only screen and (max-width: 591px) {
  .site-filters .button-sm {
    font-size: 12px;
    padding: 5px 15px;
  }
  .contact-no-area .title {
    font-size: 26px;
    line-height: 40px;
    margin-bottom: 10px;
  }
  .contact-no .no {
    font-size: 20px;
    line-height: 30px;
  }
  .contact-no .contact-right .site-button {
    padding: 12px 24px;
    font-size: 12px;
  }
  .contact-no .contact-left {
    padding-right: 15px;
  }
  .contact-no .contact-right {
    padding-left: 15px;
  }
  .counter-staus-box .section-head .title {
    font-size: 28px;
    line-height: 40px;
  }
  .service-media-bx .dlab-title {
    font-size: 20px;
    line-height: 30px;
  }
  .service-media-bx .dlab-info {
    padding: 25px 0 15px 0;
  }
  .service-media-bx {
    padding: 10px;
  }
  .ft-contact {
    margin: 0 0;
  }
  .service-box.style1 {
    padding: 30px;
    margin: 10px 20px 25px;
  }
  .service-box.style1 .title {
    font-size: 18px;
    line-height: 28px;
  }
  .project-owbx {
    padding: 20px 15px 15px;
    margin: 20px 0 30px;
  }
  .project-slider .about-content {
    padding-left: 0;
  }
  .project-info-box .project-media img {
    height: 350px;
  }
  .service-box.style4 {
    padding: 30px;
  }
  .row.sp15,
  .sp15 {
    margin-left: 0;
    margin-right: 0;
  }
  .latest-project-info {
    padding: 30px;
  }
  .latest-project-info h2 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 10px;
  }
  .social-curve {
    padding: 15px 0;
  }
  .social-curve:before {
    left: -10%;
  }
  .social-curve .social-icon .title {
    font-size: 16px;
  }
  .social-curve .social-icon li a {
    font-size: 16px;
    padding: 2px;
  }
  .box-header .extra-nav .site-button,
  .frame-box .slide-content-area,
  .header-curve.ext-header .extra-nav {
    display: none;
  }
  .header-curve.ext-header .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .frame-box .area-frame .container {
    padding: 15px;
  }
  .frame-box .service-list li:after {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
  .frame-box .service-list li {
    font-size: 16px;
    line-height: 26px;
    padding-left: 50px;
  }
  .frame-box .testimonial-text p {
    font-size: 16px;
    line-height: 28px;
  }
  .frame-box .container > .logo-header,
  .frame-box .site-header.center .container > .logo-header img {
    height: 60px;
    width: 100px;
  }
  .frame-box .main-bar {
    padding: 20px 0 20px;
  }
  .frame-box .area-frame {
    margin-bottom: 0;
  }
  .frame-box .section-full:last-child {
    margin-bottom: 0;
  }
  .section-head.style1 .title {
    font-size: 30px;
    line-height: 40px;
  }
  .section-head.style1 p {
    font-size: 14px;
  }
  .box-header.header-transparent .main-bar {
    padding: 0 15px;
  }
  .request-form .form-group {
    margin-bottom: 15px;
  }
}

.dlab-team4 {
  text-align: center;
}

.dlab-team4 .dlab-media {
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.dlab-team4 .dlab-media:after,
.dlab-team4 .dlab-media:before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 13px solid #e6e5e5;
  position: absolute;
  top: 0;
  left: 0;
}

.dlab-team4 .dlab-media:after {
  border-color: var(--color-primary) #e6e5e5 #e6e5e5 var(--color-primary);
  z-index: 1;
  transform: rotate(-10deg);
  transition: all 0.8s ease 0s;
  -moz-transition: all 0.8s ease 0s;
  -webkit-transition: all 0.8s ease 0s;
  -ms-transition: all 0.8s ease 0s;
  -o-transition: all 0.8s ease 0s;
}

.dlab-team4:hover .dlab-media:after {
  transform: rotate(350deg);
}

.dlab-team4 img {
  width: 100%;
  height: auto;
}

.dlab-team4 .dlab-title {
  font-size: 20px;
  font-weight: 700;
  color: #222;
  padding-bottom: 15px;
  margin: 15px 0 10px 0;
  position: relative;
}

.dlab-team4 .dlab-title:after {
  content: "";
  width: 30px;
  height: 2px;
  background: #222;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.dlab-team4 .dlab-position {
  display: block;
  font-size: 16px;
  font-style: italic;
  color: #777;
  margin-bottom: 10px;
}

.dlab-team4
  .dlab-social-icon
  li
  a.site-button.gray-light.
  .dlab-team5
  .dlab-social-icon
  li
  a {
  background-color: #e0e0e0;
  color: #999;
  line-height: 30px;
  margin: 0 1px;
}

.dlab-team4 .dlab-social-icon li a.site-button:hover,
.dlab-team5 .dlab-social-icon li a.site-button:hover {
  background: var(--color-primary);
  color: #fff;
}

.dlab-team5 {
  text-align: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.dlab-team5 .dlab-social-icon li {
  margin: 0 2px;
}
.dlab-team5 .dlab-border-left:after,
.dlab-team5 .dlab-border-left:before,
.dlab-team5 .dlab-border-right:after,
.dlab-team5 .dlab-border-right:before {
  content: "";
  width: 50px;
  height: 50px;
  background: var(--color-primary);
  position: absolute;
  z-index: -1;
}

.dlab-team5 .dlab-border-left:after {
  left: 0;
  top: 0;
}

.dlab-team5 .dlab-border-left:before {
  bottom: 0;
  left: 0;
}

.dlab-team5 .dlab-border-right:before {
  right: 0;
  bottom: 0;
}

.dlab-team5 .dlab-border-right:after {
  top: 0;
  right: 0;
}

.dlab-team5 .dlab-media {
  margin: 5px;
  position: relative;
  border: 3px solid #fff;
  transition: all 0.5s ease 0s;
}

.dlab-team5:hover .dlab-media {
  border-color: #33343e;
}

.dlab-team5 .dlab-media:after {
  content: "";
  width: 100%;
  height: 0;
  background: #33343e;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform-origin: 0 0 0;
  transition: all 0.5s ease 0s;
}

.dlab-team5:hover .dlab-media:after {
  height: 100%;
  opacity: 0.85;
}

.dlab-team5 img {
  width: 100%;
  height: auto;
}

.dlab-team5 .dlab-info {
  width: 100%;
  position: absolute;
  top: -50%;
  left: 0;
  transition: all 0.5s ease 0.2s;
}

.dlab-team5:hover .dlab-info {
  top: 50%;
  transform: translateY(-50%);
}

.dlab-team5 .dlab-title a {
  color: #fff;
}

.dlab-team5 .dlab-title {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 5px 0;
}

.dlab-team5 .dlab-position {
  font-size: 14px;
  color: #fff;
  line-height: 26px;
  text-transform: capitalize;
  display: block;
  margin-bottom: 10px;
}

.dlab-team6 {
  background: #000;
  text-align: center;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  position: relative;
}

.dlab-team6 img {
  width: 100%;
  height: auto;
  transition: all 0.55s ease 0s;
}

.dlab-team6:hover img {
  opacity: 0.5;
}

.dlab-team6 .dlab-info {
  padding: 5px 10px 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: rotate(45deg) translate(-50%, -50%);
  transform-origin: 0 0;
  transition: all 0.55s ease 0s;
}

.dlab-team6:hover .dlab-info {
  opacity: 1;
}

.dlab-team6 .dlab-info:after,
.dlab-team6 .dlab-info:before {
  content: "";
  width: 400px;
  height: 2px;
  background: var(--color-primary);
  position: absolute;
  top: 0;
  left: 10px;
  transform: translateX(100%);
  transition: all 0.55s ease 0s;
}

.dlab-team6 .dlab-info:after {
  top: auto;
  left: auto;
  bottom: 0;
  right: 10px;
  transform: translateX(-100%);
}

.dlab-team6:hover .dlab-info:after,
.dlab-team6:hover .dlab-info:before {
  transform: translate(0, 0);
  transition-delay: 0.15s;
}

.dlab-team6 .dlab-title {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin: 0 0 5px;
  line-height: 26px;
}

.dlab-team6 .dlab-title:after,
.dlab-team6 .dlab-title:before {
  content: "";
  width: 2px;
  height: 300px;
  background: var(--color-primary);
  position: absolute;
  top: 10px;
  right: 0;
  transform: translateY(100%);
  transition: all 0.55s ease 0s;
}

.dlab-team6 .dlab-title:after {
  top: auto;
  right: auto;
  bottom: 10px;
  left: 0;
  transform: translateY(-100%);
}

.dlab-team6:hover .dlab-title:after,
.dlab-team6:hover .dlab-title:before {
  transform: translate(0, 0);
}

.dlab-team6 .dlab-position {
  display: block;
  padding: 5px 10px;
  background: var(--color-primary);
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #fff;
  text-transform: capitalize;
}

.team-carousel .slick-center .dlab-team7,
.team-carousel .owl-item.active.center .dlab-team7 {
  background-color: #fff;
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.5s;
  transform: scale(1.05);
}

.dlab-team7 {
  padding: 30px;
  text-align: center;
  border-radius: 10px;
  margin: 30px 0;
  transition: all 0.5s;
}

.dlab-team7 .dlab-media {
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 20px;
}

.dlab-team7 .dlab-title {
  line-height: 24px;
  margin-bottom: 5px;
}

.dlab-team7 .dlab-position {
  display: block;
  margin-bottom: 10px;
}

.dlab-team8 .dlab-title {
  margin-bottom: 5px;
  margin-top: 15px;
}

.dlab-team8 .dlab-info {
  text-align: center;
}

.dlab-team8 .dlab-media {
  position: relative;
}

.dlab-team8:hover .dlab-media .dlab-social-icon {
  opacity: 1;
  transition: all 0.5s;
}

.dlab-team8 .dlab-media .dlab-social-icon {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  margin: 0;
  opacity: 1;
  transition: all 0.5s;
}

.dlab-team8 .dlab-media .dlab-social-icon li {
  width: 50%;
  position: relative;
}

.dlab-team8 .dlab-media .dlab-social-icon li a {
  border-radius: 0;
  width: 100%;
  height: 100%;
  font-size: 24px;
  padding: 30px;
  position: relative;
  transition: all 0.5s;
}

.dlab-team8 .dlab-media .dlab-social-icon li a:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dlab-team8 .dlab-media .dlab-social-icon li:nth-child(1) a {
  top: -100%;
}

.dlab-team8:hover .dlab-media .dlab-social-icon li:nth-child(1) a {
  top: 0;
  transition: all 0.5s;
}

.dlab-team8 .dlab-media .dlab-social-icon li:nth-child(4) a {
  bottom: -100%;
}

.dlab-team8:hover .dlab-media .dlab-social-icon li:nth-child(4) a {
  bottom: 0;
  transition: all 0.5s;
}

.dlab-team8 .dlab-media .dlab-social-icon li:nth-child(2) a {
  right: -100%;
}

.dlab-team8:hover .dlab-media .dlab-social-icon li:nth-child(2) a {
  right: 0;
  transition: all 0.5s;
}

.dlab-team8 .dlab-media .dlab-social-icon li:nth-child(3) a {
  left: -100%;
}

.dlab-team8:hover .dlab-media .dlab-social-icon li:nth-child(3) a {
  left: 0;
  transition: all 0.5s;
}

.dlab-team9 .dlab-title a {
  color: #fff;
  display: block;
  padding: 25px 10px;
}

.dlab-team9 .dlab-media.dlab-media-right .dlab-info-has {
  background-color: var(--color-primary);
  border-radius: 20px 0 0 20px;
  top: 50px;
  transition: all 0.8s;
  -webkit-transition: all 0.8s;
  -moz-transition: all 0.8s;
  -o-transition: all 0.8s;
}

.dlab-team9 .dlab-social-icon li a {
  vertical-align: middle;
  padding: 7px 5px;
  height: auto;
  width: auto;
  font-size: 16px;
}

.dlab-team9 .dlab-info {
  position: unset;
}

.dlab-team9 .dlab-title {
  background-color: var(--color-primary);
  font-weight: 500;
  bottom: 0;
  position: absolute;
  color: #fff;
  display: block;
  left: 0;
  font-size: 14px;
  letter-spacing: 3px;
  height: 100%;
  transition: all 0.8s;
  writing-mode: tb-rl;
  top: 0;
  margin: 0;
  transform-origin: left;
  text-align: right;
  line-height: 24px;
  text-transform: uppercase;
  opacity: 0.8;
  -ms-opacity: 0.8;
}

.testimonial-11 {
  display: flex;
  align-items: center;
  padding: 0 100px 30px;
}

.testimonial-11 .testimonial-pic {
  width: 250px;
  height: auto;
  border: 0;
  min-width: 250px;
  margin-right: 30px;
  border-radius: 10px;
  overflow: hidden;
}

.testimonial-11 .testimonial-text {
  padding: 0 0 15px;
}

.testimonial-11 .testimonial-name {
  font-size: 20px;
  line-height: 30px;
}

.testimonial-11 .testimonial-text p {
  line-height: 32px;
  font-size: 18px;
  font-style: italic;
}

.owl-btn-white .owl-nav .owl-next,
.owl-btn-white .owl-nav .owl-prev {
  color: #fff;
  opacity: 0.8;
}

.testimonial-10 .testimonial-text,
.testimonial-9 .testimonial-text {
  margin-bottom: 10px;
}

.testimonial-10 .testimonial-text p,
.testimonial-9 .testimonial-text p {
  font-size: 16px;
}

.testimonial-10 .testimonial-name,
.testimonial-9 .testimonial-name {
  font-size: 18px;
  text-transform: capitalize;
}

.testimonial-10 .testimonial-position,
.testimonial-9 .testimonial-position {
  text-transform: capitalize;
  font-size: 14px;
  color: var(--color-primary);
}

.testimonial-11.testimonial-box {
  background-color: #fff;
  box-shadow: 5px 5px 30px 0 rgba(0, 0, 0, 0.1);
  margin: 30px 100px;
  padding: 40px;
}

.testimonial-11.testimonial-box .testimonial-pic {
  border-radius: 0;
}

.testimonial-11.testimonial-box .quote-left:before,
.testimonial-11.testimonial-box .quote-right:after {
  font-size: 50px;
  color: rgba(0, 0, 0, 0.1);
}

.testimonial-11.testimonial-box .testimonial-detail {
  padding: 50px 85px;
}

.testimonial-11.testimonial-box .quote-left:before {
  left: 20px;
  top: 0;
}

.testimonial-11.testimonial-box .quote-right:after {
  top: auto;
  right: 50px;
  bottom: 50px;
}

.testimonial-11.testimonial-box .testimonial-text p {
  line-height: 28px;
  font-size: 16px;
}

.owl-btn-2.radius-no .owl-nav .owl-next,
.owl-btn-2.radius-no .owl-nav .owl-prev {
  border-radius: 0;
}

.owl-btn-2.owl-btn-md .owl-nav .owl-next,
.owl-btn-2.owl-btn-md .owl-nav .owl-prev {
  height: 60px;
  width: 60px;
  line-height: 60px;
  font-size: 24px;
}

.testimonial-box-carousel .owl-nav .owl-prev {
  left: 140px;
}

.testimonial-box-carousel .owl-nav .owl-next {
  right: 140px;
}

.testimonial-12 {
  display: flex;
}

.testimonial-12 .testimonial-pic {
  padding: 0;
  width: 200px;
  min-width: 200px;
  height: 200px;
  border: 0;
  margin: 30px -80px 30px 0;
}

.testimonial-12 .testimonial-text {
  padding: 0;
}

.testimonial-12 .testimonial-detail {
  background-color: var(--color-primary);
  padding: 50px 30px 50px 110px;
}

.testimonial-12 h4.testimonial-name {
  font-size: 22px;
}

.testimonial-12 .testimonial-position {
  font-size: 16px;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.testimonial-num-count {
  width: 750px;
  margin: auto !important;
}

.owl-num-count .owl-dots {
  text-align: right;
  counter-reset: li;
}

.owl-num-count .owl-dots {
  text-align: right;
  counter-reset: li;
  justify-content: flex-end;
  display: flex;
}

.owl-num-count .owl-dots .owl-dot {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  font-size: 18px;
}

.owl-num-count .owl-dots .owl-dot span {
  margin: 0;
  width: 0;
  border-radius: 0;
  height: 2px;
  transition: all 0.5s;
  margin-left: 5px;
}

.owl-num-count .owl-dots .owl-dot.active:before {
  color: #000;
}

.owl-num-count .owl-dots .active.owl-dot span {
  width: 40px;
  background-color: #000;
}

.owl-num-count .owl-dots .owl-dot:before {
  content: counter(li, decimal);
  counter-increment: li;
  font-weight: 500;
  color: #a0a0a0;
}

.testimonial-13 {
  border: 1px solid rgba(0, 0, 0, 0.09);
  box-shadow: 5px 25px 35px -35px #736d6d;
  margin: 0;
  border-radius: 10px;
  background-color: #fff;
  transition: all 0.5s;
}

.testimonial-13 .testimonial-detail {
  padding: 25px 0 20px 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.09);
}

.testimonial-13 .testimonial-pic {
  width: 70px;
  height: 70px;
  border: 0;
  float: left;
  margin-right: 15px;
}

.testimonial-13 .testimonial-text {
  padding: 85px 25px 25px;
}

.testimonial-13 .quote-left:before {
  font-size: 50px;
  color: var(--color-primary);
  top: -75px;
}

.testimonial-13-area.owl-theme .owl-dots {
  margin-top: 0;
}

.owl-item.active.center .testimonial-13 .testimonial-text {
  background-color: transparent;
  color: #6f6f6f;
}

.owl-item.active.center .testimonial-13 {
  margin-top: 0;
  transition: all 0.5s;
}

.testimonial-14 {
  border: 10px solid;
  padding: 40px 0 25px 0;
  margin: 30px 30px 10px;
  text-align: center;
  position: relative;
}

.testimonial-14 .testimonial-detail {
  padding: 7px 0;
  margin: 0 -30px 20px;
  border: 7px solid #fff;
  background: var(--color-secondry);
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: relative;
}

.testimonial-14 .testimonial-detail:before {
  content: "";
  border-top: 15px solid;
  border-left: 15px solid transparent;
  border-bottom: 15px solid transparent;
  position: absolute;
  bottom: -37px;
  left: 0;
}

.testimonial-14 .testimonial-detail:after {
  content: "";
  border-top: 15px solid;
  border-right: 15px solid transparent;
  border-bottom: 15px solid transparent;
  position: absolute;
  bottom: -37px;
  right: 0;
}

.testimonial-14.quote-left:before {
  width: 100px;
  height: 100px;
  line-height: 100px;
  background: #fff;
  margin: 0 auto;
  font-size: 70px;
  font-weight: 900;
  color: var(--color-primary);
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
}

.bg-gray .testimonial-14.quote-left:before {
  background: #f7f9fb;
}

.testimonial-14 .testimonial-detail {
  border-color: #f7f9fb;
}

.testimonial-15 {
  margin: 0 0 20px;
  position: relative;
}

.testimonial-15 .testimonial-pic {
  width: 70px;
  height: 70px;
  border: 3px solid #fff;
  float: left;
  margin-right: 15px;
}

.testimonial-15:after {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  right: -2px;
  background: #fff;
  top: 0;
  opacity: 0.2;
}

.testimonial-15 .testimonial-text {
  padding: 40px 30px 20px 0;
}

.testimonial-15 .testimonial-text p {
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
}

.testimonial-15 .testimonial-name {
  margin-top: 10px;
}

.testimonial-15 .testimonial-detail {
  padding: 0;
}

.testimonial-15 .quote-left:before,
.testimonial-15 .quote-right:after {
  font-size: 24px;
  line-height: 24px;
  color: var(--color-primary);
}

.testimonial-15 .quote-right:before {
  top: 0;
}

.testimonial-15 .quote-right:after {
  top: auto;
  bottom: 0;
  right: 30px;
}

.testimonial-15 .testimonial-position {
  color: var(--color-primary);
}

.testimonial-15.text-white .quote-left:before,
.testimonial-15.text-white .quote-right:after,
.testimonial-15.text-white .testimonial-position {
  color: rgba(255, 255, 255, 0.8);
}

.section-full.bg-black .testimonial-15 .quote-left:before,
.section-full.bg-black .testimonial-15 .quote-right:after,
.section-full.bg-secondry .testimonial-15 .quote-left:before,
.section-full.bg-secondry .testimonial-15 .quote-right:after {
  color: var(--color-primary);
}

.portfolio-style1 {
  position: relative;
  overflow: hidden;
}

.portfolio-style1 .portfolio-info .title {
  font-size: 26px;
}

.portfolio-style1 .portfolio-info {
  position: absolute;
  left: 0;
  bottom: -100%;
  padding: 30px 200px 30px 30px;
  transition: all 0.8s;
  z-index: 99;
}

.portfolio-style1 .portfolio-info .title a,
.portfolio-style1 .portfolio-info p {
  color: #fff;
}

.portfolio-style1 .portfolio-info p {
  margin-bottom: 0;
}

.project-carousel .owl-item.active.center .portfolio-style1 .portfolio-info {
  transition: all 0.8s;
  bottom: 0;
}

.project-carousel
  .owl-item.active.center
  .portfolio-style1
  .dlab-img-overlay1:before {
  opacity: 0.5;
}

.call-action.style2 h2.title {
  font-size: 36px;
  line-height: 46px;
  font-family: poppins;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 15px;
}

.call-action.style2 {
  padding: 0;
}

.call-action.style2 h3 {
  font-size: 26px;
  font-weight: 600;
  font-family: poppins;
  line-height: 36px;
  margin-bottom: 15px;
}

.call-action.style2 i.call-icon {
  font-size: 100px;
  margin-bottom: 50px;
  position: relative;
}

.call-action.style2 i.call-icon:after {
  content: "\f10b";
  font-size: 45%;
  color: var(--color-primary);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.service-box-5 .dlab-info {
  margin-top: 20px;
}

.service-box-5 .icon-bx {
  float: left;
  margin-right: 20px;
}

.service-box-5 .dlab-info .title {
  font-size: 20px;
  margin-bottom: 5px;
}

.service-box-5 .dlab-info p {
  line-height: 26px;
}

.site-footer.style2 .footer-top {
  background-color: #fff;
  position: relative;
}

.site-footer.style2 .recent-posts-entry .widget-post {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.site-footer.style2 .recent-posts-entry .post-title {
  line-height: 30px;
  font-size: 18px;
  font-weight: 600;
}

.site-footer.style2 .footer-title,
.site-footer.style2 .recent-posts-entry .post-date strong,
.site-footer.style2 .recent-posts-entry .post-title a,
.site-footer.style2 .widget .list-2 li a,
.site-footer.style2 .widget_getintuch li {
  color: #000;
}

.site-footer.style2 .container-fluid {
  padding-left: 50px;
  padding-right: 50px;
}

.site-footer.style2 .subscribe-form input {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-right: 0;
}

.site-footer.style2 .subscribe-form .input-group-btn {
  padding-left: 0;
}

.site-footer.style2 .widget {
  padding: 0 5px;
  z-index: 1;
  position: relative;
}

.site-footer.style2 .footer-top:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 33.33%;
  background-color: #f1f1f1;
  z-index: 0;
}

.site-footer.style2 .widget .list-2 li {
  padding: 8px 8px 8px 20px;
  font-size: 16px;
  color: #000;
  font-weight: 600;
}

.site-footer.style2 .widget p {
  font-size: 16px;
  line-height: 28px;
  color: #747477;
}

.site-footer.style2 .widget.widget_about {
  padding: 0 50px 0 5px;
}

.site-footer.style2 .widget.widget_getintuch li i {
  color: var(--color-primary);
  top: 0;
}

.site-footer.style2 .footer-logo {
  margin-bottom: 30px;
  width: 200px;
}

.site-footer.style2 .widget.widget_getintuch {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px 0 0;
  margin-top: 20px;
}

.site-footer.style2 .widget.widget_getintuch li {
  font-size: 15px;
  line-height: 24px;
  text-transform: capitalize;
  color: #747477;
}

.site-footer.style2 .site-button-link i {
  margin-left: 0;
}

@media only screen and (max-width: 1024px) {
  .site-footer.style2 .footer-top {
    padding: 60px 0 0;
  }
  .site-footer.style2 .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .site-footer.style2 .widget.widget_about {
    padding: 0 5px;
  }
  .testimonial-box-carousel .owl-nav .owl-next {
    right: 60px;
  }
  .testimonial-box-carousel .owl-nav .owl-prev {
    left: 60px;
  }
  .testimonial-11.testimonial-box {
    padding: 30px;
  }
  .testimonial-11.testimonial-box .quote-left:before,
  .testimonial-11.testimonial-box .quote-right:after {
    font-size: 36px;
  }
  .testimonial-11.testimonial-box .testimonial-detail {
    padding: 50px;
  }
  .testimonial-11 .testimonial-pic {
    margin-right: 15px;
  }
  .testimonial-num-count {
    width: 100%;
    padding: 0 50px;
  }
  .header-nav .nav > li .sub-menu.right {
    right: 0;
    left: auto;
  }
  .portfolio-style1 .portfolio-info {
    padding: 30px;
  }
  .dlab-quik-search span {
    right: 30px;
  }
  .dlab-quik-search.On form {
    max-width: 100%;
    padding: 0 30px;
  }
  .box-header .extra-nav .site-button {
    height: 65px;
  }
}

@media only screen and (max-width: 991px) {
  .site-footer.style2 .footer-top:after {
    content: unset;
  }
  .testimonial-11 {
    padding: 0 60px 30px;
  }
  .testimonial-11 .testimonial-text p {
    line-height: 30px;
    font-size: 16px;
  }
  .testimonial-11 .testimonial-pic {
    width: 150px;
    min-width: 150px;
    margin-right: 20px;
  }
  .testimonial-11.testimonial-box {
    margin: 20px 10px;
  }
  .testimonial-box-carousel .owl-nav .owl-next {
    right: 0;
  }
  .testimonial-box-carousel .owl-nav .owl-prev {
    left: 0;
  }
  .testimonial-12 .testimonial-pic {
    width: 150px;
    min-width: 150px;
    height: 150px;
    margin: 25px -80px 25px 0;
  }
  .testimonial-12 .testimonial-detail {
    padding: 25px 25px 25px 100px;
  }
  .dlab-team4 {
    padding: 0 50px;
  }
  .dlab-team6 .dlab-position {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .dlab-team6 .dlab-title {
    font-size: 16px;
    line-height: 22px;
  }
  .header-nav .nav > li:active > a,
  .header-nav .nav > li:focus > a,
  .header-nav .nav > li:hover > a {
    color: #313444 !important;
  }
  .frame-box .gallery-bx > div:last-child {
    padding-left: 15px;
  }
  .frame-box .gallery-bx > div:first-child {
    padding-right: 15px;
    border-right: 0;
  }
  .frame-box .section-head {
    margin-bottom: 30px;
  }
  .frame-box .content-inner,
  .frame-box .content-inner-1 {
    padding-top: 60px;
  }
  .frame-box .client-carousel .owl-nav .owl-prev {
    left: 0;
  }
  .frame-box .client-carousel .owl-nav .owl-next {
    right: 0;
  }
  .portfolio-style1 .portfolio-info p {
    font-size: 14px;
    line-height: 24px;
  }
  .portfolio-style1 .portfolio-info {
    padding: 30px 250px 30px 30px;
  }
  .site-footer.footer-full .p-tb60 {
    padding-bottom: 30px;
  }
  .footer-box .title,
  .header-box .title {
    font-size: 30px;
    letter-spacing: 1px;
    margin-bottom: 10px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .site-footer.style2 .widget {
    margin-bottom: 30px;
  }
  .site-footer.style2 .footer-top {
    padding: 40px 0 0;
  }
  .site-footer.style2 .widget.widget_about {
    margin-bottom: 40px;
  }
  .site-footer.style2 .widget .subscribe-form {
    margin-top: 15px;
  }
  .testimonial-11 .testimonial-pic {
    width: 100px;
    min-width: 100px;
    margin-right: 15px;
  }
  .testimonial-11 .testimonial-text p,
  .testimonial-11.testimonial-box .testimonial-text p,
  .testimonial-15 .testimonial-text p {
    line-height: 26px;
    font-size: 14px;
  }
  .testimonial-11 {
    padding: 0 50px 10px;
  }
  .testimonial-11 .testimonial-name {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0;
  }
  .testimonial-11.testimonial-box .quote-left:before,
  .testimonial-11.testimonial-box .quote-right:after {
    font-size: 30px;
  }
  .testimonial-11.testimonial-box .quote-left:before {
    left: 0;
  }
  .testimonial-11.testimonial-box .quote-right:after {
    right: 0;
  }
  .testimonial-11.testimonial-box .testimonial-detail {
    padding: 45px 10px 0 0;
  }
  .testimonial-11.testimonial-box {
    padding: 20px;
  }
  .testimonial-11.testimonial-box {
    margin: 15px 25px;
  }
  .owl-btn-2.owl-btn-md .owl-nav .owl-next,
  .owl-btn-2.owl-btn-md .owl-nav .owl-prev {
    height: 45px;
    width: 45px;
    line-height: 45px;
    font-size: 20px;
  }
  .testimonial-box-carousel .owl-nav .owl-next {
    right: -10px;
  }
  .testimonial-box-carousel .owl-nav .owl-prev {
    left: -10px;
  }
  .testimonial-12 .testimonial-detail {
    padding: 70px 30px 30px;
    text-align: center;
  }
  .testimonial-12 {
    display: block;
  }
  .testimonial-12 .testimonial-pic {
    width: 100px;
    min-width: 100px;
    height: 100px;
    margin: 0 auto -50px;
    display: block;
  }
  .dlab-team7 .dlab-title {
    line-height: 24px;
    font-size: 18px;
  }
  .dlab-team4 {
    padding: 0 10px;
  }
  .frame-box .content-inner,
  .frame-box .content-inner-1 {
    padding-top: 30px;
  }
  .frame-box .testimonial-text p {
    font-size: 18px;
    line-height: 30px;
  }
  .frame-box .service-list {
    margin-bottom: 0;
  }
  .box-header .extra-nav .site-button {
    height: 50px;
  }
  .banner-form .bootstrap-select.btn-group .dropdown-toggle .caret {
    top: 19px;
  }
  .cargo-banner .title {
    font-size: 36px;
    line-height: 46px;
  }
  .portfolio-style1 .portfolio-info {
    padding: 30px;
  }
  .call-action.style2 i.call-icon {
    font-size: 70px;
    margin-bottom: 20px;
  }
  .call-action.style2 h2.title {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 10px;
  }
  .call-action.style2 h3 {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 10px;
  }
  .contact-action {
    padding: 50px 0 20px;
  }
  .contact-action.style2 {
    padding-bottom: 50px;
  }
  .footer-box .title,
  .header-box .title {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 591px) {
  .site-footer.style2 .recent-posts-entry .post-title {
    line-height: 28px;
    font-size: 16px;
  }
  .site-footer.style2 .recent-posts-entry .widget-post {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  .site-footer.style2 .widget .list-2 li {
    padding: 5px 5px 5px 20px;
    font-size: 14px;
  }
  .site-footer.style2 .footer-logo {
    margin-bottom: 20px;
    width: 180px;
  }
  .testimonial-11 {
    display: block;
  }
  .testimonial-num-count {
    padding: 0;
  }
  .testimonial-14 .testimonial-detail {
    margin: 0 -30px 0;
  }
  .testimonial-14 .testimonial-text {
    padding: 15px 15px 0;
  }
  .dlab-team4 {
    padding: 0 30px;
  }
  .service-box-2 .dlab-info {
    padding: 20px 10px 10px;
  }
  .frame-box .footer-title {
    margin-bottom: 20px;
  }
  .contact-action {
    padding: 30px 0 0;
  }
  .testimonial-10 .testimonial-pic {
    position: relative;
  }
  .testimonial-10 {
    padding-left: 0;
    text-align: center;
  }
  .site-footer .widget .m-b30 {
    margin-bottom: 15px;
  }
  .banner-form .bootstrap-select .dropdown-toggle,
  .banner-form .form-control,
  .banner-form .site-button {
    height: 45px;
    font-size: 15px;
  }
  .banner-form .bootstrap-select.btn-group .dropdown-toggle .caret {
    top: 17px;
  }
  .cargo-banner .title {
    font-size: 26px;
    line-height: 36px;
  }
  .portfolio-style1 .portfolio-info {
    padding: 20px;
  }
  .portfolio-style1 .portfolio-info .title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .site-footer.footer-full .p-tb60 {
    padding-top: 50px;
    padding-bottom: 20px;
  }
  .testimonial-11 {
    padding: 0 10px 10px;
    text-align: center;
  }
  .testimonial-11-area .owl-nav {
    display: none;
  }
  .contact-action.style2 {
    padding-bottom: 30px;
  }
  .pricingtable-style2-area {
    margin-top: 0;
  }
  .content-inner {
    padding-bottom: 0;
    padding-top: 30px;
  }
  .inquiry-form .form-control {
    font-size: 14px;
  }
  .inquiry-form .form-group {
    margin-bottom: 15px;
  }
  .counter-style-5 p {
    font-size: 12px;
  }
  .counter-style-5 {
    padding: 30px 10px;
  }
  .footer-box .title,
  .header-box .title {
    font-size: 24px;
    margin-bottom: 10px;
    line-height: 34px;
  }
  .footer-box,
  .header-box {
    padding: 20px 20px 0 20px;
  }
}

.dlab-social-icon.border,
.dlab-tabs.bg-tabs.vertical.border,
.dlab-tabs.bg-tabs.vertical.right.border,
.dlab-tabs.border,
.dlab-tabs.border-top,
.dlab-tabs.border-tp.border.bg-tabs,
.dlab-tabs.border.bg-tabs,
.dlab-tabs.vertical.border,
.dlab-tabs.vertical.right.border {
  border: 0 !important;
}

.inquiry-form .input-group-addon textarea {
  border-bottom: 0;
}

.portfolio-ic .check-km i.icon-bx-xs {
  color: #000 !important;
}

.portfolio-ic .check-km i.icon-bx-xs:hover {
  background: 0 0 !important;
  border: 2px solid #fff;
  color: #fff !important;
}

.navstyle2 .header-nav .nav > li.active > a:after,
.navstyle2 .header-nav .nav > li:hover > a:after {
  background-color: transparent;
}

.white.btnhover13:hover::after,
.white.btnhover14:hover::after,
.white.btnhover15:hover::after {
  background-color: #f4f4f4;
}

.footer-top.bg-primary .subscribe-form .form-control::placeholder {
  color: #fff;
}

[type="button"]:focus {
  outline: 0;
}

.inquiry-form .bootstrap-select {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.inquiry-form .bootstrap-select .dropdown-toggle {
  border-width: 0 0 1px 0 !important;
  border-radius: 0;
  font-size: 16px;
  color: #777;
  border-color: #e1e6eb !important;
  padding-left: 12px;
}

.inquiry-form .bootstrap-select.btn-group .dropdown-menu {
  border: 0;
  box-shadow: -5px 5px 10px 0 rgba(0, 0, 0, 0.1);
}

.inquiry-form .bootstrap-select div.dropdown-menu ul li a {
  padding: 8px 15px;
  font-size: 14px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu,
  .header-nav .nav > li .sub-menu,
  .header-nav .nav > li ul {
    background: 0 0 !important;
  }
  .dlab-bnr-inr-entry {
    display: table-cell;
    vertical-align: middle;
  }
  .frame-box .mo-left .header-nav .logo-header:after {
    content: none;
  }
}

@media only screen and (max-width: 767px) {
  .dlab-topbar-left ul li:after {
    right: 0;
  }
  .frame-box .footer-top {
    padding: 40px 0 15px;
    margin-top: 10px;
  }
  .frame-box .ft-contact {
    padding-bottom: 0;
  }
  .frame-project-area .m-t40,
  .frame-sr-list-area .m-t40 {
    margin-top: 20px;
  }
  .gallery-bx .m-t20 {
    margin-top: 10px;
  }
  .site-footer.map-footer .p-tb60 {
    padding: 35px 10px;
  }
  .site-filters {
    margin-bottom: 15px;
  }
  .service-box-4-area.m-b30 {
    margin-bottom: 0;
  }
  .portbox3 .portinner {
    max-width: 80%;
    width: 80%;
    min-width: 80%;
  }
  .area-frame {
    margin-bottom: 50px;
  }
  .frame-box .site-header.center .container > .logo-header {
    top: 5px;
  }
  .section-content.box-sort-in.m-t80 {
    margin-top: 0;
  }
  .section-content.box-sort-in.p-t50.p-t10 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .section-content.box-sort-in.p-tb50 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .dlab-accordion.faq-1.m-b30,
  .pricing-table-title {
    margin-bottom: 0;
  }
  .pricing-table-box-area .section-content.p-tb30 {
    padding-top: 0;
  }
  .dz_error-405 .widget {
    margin-bottom: 10px;
  }
  .section-full.p-b80 {
    padding-bottom: 50px;
  }
  .alert-box-area .dlab-divider,
  .tabs-box-area .dlab-divider,
  .team-box-area .dlab-divider {
    margin: 0;
  }
  .pricing-table-box-area .pricingtable-row.m-b30,
  .pricing-table-col-gap .pricingtable-wrapper {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 591px) {
  .service-box.style3 .icon-bx-wraper {
    padding: 35px 30px;
  }
  .about-industry .img-cover {
    min-height: 300px;
  }
  .counter-staus-box .play-btn {
    margin-top: 0;
  }
  .frame-box .site-header.center .container > .logo-header {
    top: 15px;
  }
  form .g-recaptcha {
    margin-bottom: -25px;
  }
  .shop-cart .dropdown-menu.cart-list {
    top: 50px;
  }
  .shop-account h2 {
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 25px;
  }
  .blog-md.blog-post .dlab-pfost-media,
  .blog-md.blog-post .dlab-post-info {
    margin-bottom: 0;
  }
  .pagination-bx {
    margin-bottom: 10px;
  }
  .pagination-bx.m-b30 {
    margin-bottom: 30px;
  }
  .alignleft,
  .alignright {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  blockquote {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

/* React CSS */
.slick-slider {
  margin: 0 -15px;
}
.item {
  padding: 0 15px;
}
.about-ser-carousel {
  margin-bottom: 30px;
}
.about-ser-carousel .slick-list {
  overflow: unset;
}
.about-carousel-ser {
  overflow: hidden;
}
.about-ser-carousel .slick-slide {
  opacity: 0.2;
}
.about-ser-carousel .slick-active {
  opacity: 1;
}

/* Btn Style */
.btn-style-1 .slick-prev,
.btn-style-1 .slick-next {
  width: auto;
  height: auto;
  z-index: 1;
  margin: 0 -30px;
  opacity: 0.2;
}
.btn-style-1 .slick-prev:hover,
.btn-style-1 .slick-next:hover {
  opacity: 1;
}
.btn-style-2 .slick-prev:before,
.btn-style-1 .slick-prev:before,
.btn-style-2 .slick-next:before,
.btn-style-1 .slick-next:before {
  font-family: themify;
  color: #000;
  font-size: 35px;
  height: auto;
  width: auto;
}

.btn-style-2 .slick-prev:before,
.btn-style-1 .slick-prev:before {
  content: "\e64a";
}
.btn-style-2 .slick-next:before,
.btn-style-1 .slick-next:before {
  content: "\e649";
}
.btn-style-1.icon-2 .slick-prev:before {
  content: "\e629";
}
.btn-style-1.icon-2 .slick-next:before {
  content: "\e628";
}
.btn-style-1.btn-white .slick-prev:before,
.btn-style-1.btn-white .slick-next:before {
  color: #fff;
}
.btn-style-2 .slick-prev,
.btn-style-2 .slick-next {
  width: 45px;
  height: 45px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.5);
  margin: 0 50px;
  z-index: 1;
}
.btn-style-2.btn-color .slick-prev,
.btn-style-2.btn-color .slick-next {
  background: var(--color-primary);
}
.btn-style-2.out .slick-prev,
.btn-style-2.out .slick-next {
  margin: 0px -30px;
}
.btn-style-2 .slick-prev:before,
.btn-style-2 .slick-next:before {
  font-size: 16px;
  color: #fff;
}
.dots-defult .slick-dots li,
.dots-long .slick-dots li {
  width: auto;
  height: auto;
}
.dots-defult .slick-dots li button:before,
.dots-long .slick-dots li button:before {
  content: none;
}
.dots-defult .slick-dots li button,
.dots-long .slick-dots li button {
  margin: 0;
  padding: 0;
  height: 10px;
  width: 10px;
  background: #fff;
  border-radius: 10px;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
.dots-defult.d-primary .slick-dots li button,
.dots-long.d-primary .slick-dots li button {
  background: var(--color-primary);
  opacity: 0.5;
}
.dots-long .slick-dots li.slick-active button {
  width: 25px;
  opacity: 1;
}
.dots-defult .slick-dots li.slick-active button {
  opacity: 1;
}
.dots-defult .slick-dots,
.dots-long .slick-dots {
  position: unset;
  bottom: 0;
  margin-top: 20px;
}
.client-logo-carousel .ow-client-logo img {
  height: 100px;
  max-height: initial;
  width: auto;
  display: inline-block;
  object-fit: contain;
}
.client-logo-carousel .slick-list {
  padding: 10px 0;
}
.exhibition-carousel .slick-dots {
  width: 20px;
  margin: 0;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  height: auto;
  bottom: auto;
}
.exhibition-carousel .slick-dots li.slick-active button {
  background: var(--color-primary);
}
.exhibition-carousel .slick-dots li button {
  width: 12px;
  height: 12px;
}
/* Home Bnr1 */
.react-parallax {
  width: 100%;
}
.home-bnr1 img {
  width: 700px;
  max-width: initial;
}
/* Home Slider 2 */
.home-slider2 .slider-content .sliderStyle2 h1 {
  font-size: 90px;
  font-weight: 700;
  line-height: 1.1;
  max-width: 100%;
  flex: 0 0 100%;
  margin-bottom: 15px;
}
.home-slider2 .sliderStyle2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.home-slider2 .slider-content .sliderStyle2 p {
  max-width: 33%;
  margin-bottom: 0;
  padding-right: 30px;
}
/* Home Slider 3 */
.slider-wrapper .slider-content .sliderStyle3 {
  padding: 0;
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.slider-wrapper .slider-content .sliderStyle3 h1 {
  font-size: 60px;
  font-weight: 600;
}
.post-style-3 .dlab-post-info {
  border: 1px solid #eee;
  padding: 20px;
}
.post-style-3 .dlab-post-readmore {
  margin-bottom: 0;
}
.sliderStyle4 button {
  margin-right: 15px;
}
/* Home 4 */
.icon-white .btn-style-1 .slick-prev:before,
.icon-white .btn-style-1 .slick-next:before {
  color: #fff;
}
.btn-style-1.client-btn .slick-prev,
.btn-style-1.client-btn .slick-next {
  width: 50px;
  height: 50px;
  display: block;
  background: var(--color-primary);
  opacity: 1;
  margin: 0 110px;
}
.btn-style-1.client-btn .slick-prev:before,
.btn-style-1.client-btn .slick-next:before {
  color: #fff;
  font-size: 18px;
}
.project-carousel-2 {
  position: relative;
  z-index: 1;
}
.sticky-inner-wrapper {
  transform: none !important;
  -moz-transform: none !important;
  -o-transform: none !important;
  -webkit-transform: none !important;
  -ms-transform: none !important;
}
.portfolio-carousel-nogap {
  margin: 0 !important;
}
.faq-2 .accodion-title {
  margin-bottom: -1px;
}

@media only screen and (max-width: 1200px) {
  .btn-style-1 .slick-prev,
  .btn-style-1 .slick-next {
    margin: 0 40px;
  }
  .contact-no-area .title,
  .slider-content .inner h1,
  .hero-section .title {
    line-height: 1.3;
    font-size: 50px;
  }
  .home-slider2 .slider-content .sliderStyle2 h1 {
    font-size: 70px;
  }
  .home-slider2 .slider-content .sliderStyle2 p {
    max-width: 45%;
  }
  .home-slider2 .slider-content .sliderStyle2 {
    padding: 0 40px 40px 40px;
  }
  .slider-wrapper .slider-content .sliderStyle3 h1 {
    font-size: 50px;
  }
  .btn-style-2.out .slick-prev,
  .btn-style-2.out .slick-next {
    margin: 0px 20px;
  }
}
@media only screen and (max-width: 991px) {
  .home-bnr1,
  .home-bnr1.hero-section .title,
  .hero-content {
    text-align: center;
  }
  .contact-no-area .title,
  .slider-content .inner h1,
  .hero-section .title {
    font-size: 40px;
  }
  .home-bnr1 .hero-content {
    margin-bottom: 30px;
  }
  .home-bnr1 img {
    width: 80%;
    max-width: 80%;
  }
  .has-mega-menu.homedemo .mega-menu {
    height: auto;
  }
  .header .sticky-inner-wrapper {
    transform: unset !important;
    -moz-transform: unset !important;
    -webkit-transform: unset !important;
    -ms-transform: unset !important;
    -o-transform: unset !important;
  }
  .home-slider2 .slider-content .inner p {
    max-width: 60%;
  }
  .slider-content .inner.sliderStyle3 h1 {
    width: 100%;
    padding: 0 50px;
  }
  .slick-slider.project-list,
  .project-list {
    margin-bottom: 20px;
  }
  .btn-style-1.client-btn .slick-prev,
  .btn-style-1.client-btn .slick-next {
    margin: 0 30px;
  }
  .exhibition-carousel {
    margin-bottom: 30px !important;
  }
  .portbox1.style1 {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .home-slider2 .slider-content .sliderStyle2 h1 {
    font-size: 40px;
  }
  .home-slider2 .slider-content .sliderStyle2 p {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .on-show-slider {
    overflow: hidden;
  }
  .project-carousel-2 {
    margin: 0;
  }
  .btn-style-2.out .slick-prev,
  .btn-style-2.out .slick-next {
    margin: 0px 35px;
  }
}

@media only screen and (max-width: 576px) {
  .contact-no-area .title,
  .slider-content .inner h1,
  .hero-section .title {
    font-size: 28px;
    line-height: 1.5;
  }
  .hero-section .description {
    line-height: 1.5;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .hero-section a {
    padding: 0 25px;
    line-height: 45px;
    margin: 0 10px;
  }
  .home-slider2 .slider-content .sliderStyle2 {
    padding: 0 20px 0px 40px;
    text-align: center;
    bottom: auto;
    top: 50%;
    margin-top: -25%;
  }
  .slider-content .inner.sliderStyle3 h1 {
    padding: 0 40px;
    font-size: 28px;
  }
}
